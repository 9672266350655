import { toggleModal } from "./toggle-modal";

export function getAssociatedGatedContentBlocks(modalID: string) {
    const gatedContentBlocks = document.querySelectorAll(".gated-content-block");
    const associatedBlock = [...gatedContentBlocks].filter(block => {
        const associatedIds = block.getAttribute("data-associatedblock")?.split(",");
        if (associatedIds) {
            const matches = associatedIds.filter(x => x === modalID);
            if (matches.length > 0) return true;
        }
        return false;
    });
    if (associatedBlock.length > 0) return associatedBlock;
    return false;
}

function initialiseGatedContentBlocks() {
    const gatedContentBlocks = document.querySelectorAll(".gated-content-block");
    gatedContentBlocks.forEach(contentBlock => {
        const associatedBlockId = contentBlock.getAttribute("data-associatedblock");
        if (!associatedBlockId) return;
        const associatedBlockIds = associatedBlockId.split(",");
        associatedBlockIds.forEach(blockId => {
            const associatedBlockIdFlag = localStorage.getItem(blockId);
            if (!associatedBlockId || associatedBlockIdFlag !== "registered") return;
            contentBlock.classList.add("unlocked");
            markAllAssociatedModelsAsRegistered(associatedBlockIds);
        })
    })
}

function markAllAssociatedModelsAsRegistered(associatedBlockIds: string[]) {
    associatedBlockIds.forEach(blockId => {
        const isRegistered = localStorage.getItem(blockId) === "registered";
        if (isRegistered) return;

        localStorage.setItem(blockId, "registered");
        const modalElement = document.getElementById(blockId);
        if (modalElement) {
            toggleModal(modalElement, true, true);
        }
    })
}

document.addEventListener("DOMContentLoaded", initialiseGatedContentBlocks);