//get the Gateway
require("gateway.js");

//adding spin.js which might be a global dependancy.
import {Spinner} from 'spin.js';
window.Spinner = Spinner;

//spinner - based on spin.js -- does not seem to be working!
require("./spinner.js");

// The webinar and event promo banner
require("./generic-promo-banner.js");

// To remove widows.
require("./widont");

// To convert EPi dropdowns to custom dropdowns.
require("./custom-dropdown");

//This was in the original US bundle -- not sure what it does though.
require("./personalized-content.js");

// To enable interstitial popups.
require("./interstitial");

// To drive search functionality.
require("./search");

// Miscellaneous code.
require("./components");

// Miscellaneous code.
require("./lazy-loader");

//sticky premium content form
require("./sticky-gated-content/sticky-premium-form");

// AHP, Menu, Masthead and footer related code.
require("./menu-masthead-footer");

//Insights components
require("./insights-components");

//Video-player
require("./video-player");

//Select country automatically based on current URL.
require('./cta-country-dropdown-autoselect');

//export jQuery for outside use.
//window.$ = $;

//Video banner
require("./home-page");

// Expandable menu/ Floating nav
require("./expandable-nav");