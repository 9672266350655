import { getAssociatedGatedContentBlocks } from "./gated-content-block";

let openModals: Element[] = [];
let queuedModals: Element[] = [];

export function checkIfGatedContentPage() {
    return document.body.classList.contains('gated-content-page');
}
export function initialiseModal(modal: Element) {
    const isGatedContentPage = checkIfGatedContentPage();
    isGatedContentPage ? bindCloseButton(modal, true) : bindCloseButton(modal);
    moveDisclaimer(modal);
    //get timeout
    let timeoutSeconds = 8;
    const timeout = modal.getAttribute('data-timeout');
    if (timeout && !isNaN(parseInt(timeout))) {
        timeoutSeconds = parseInt(timeout);
    }
    window.setTimeout(() => toggleModal(modal), 1000 * timeoutSeconds);
}

export function toggleModal(modal: Element, hide: boolean = false, neverShowAgain: boolean = false) {
    const modalId = modal.getAttribute('id') ?? new Date().getTime().toString();

    if (hide) {
        modal.classList.remove('show');
        toggleBackDrop(false, modal);
        openModals = openModals.filter(x => x !== modal);
        if (queuedModals.length > 0) {
            toggleModal(queuedModals.shift()!, false, false);
        }

        //save in session storage, that the modal was closed.
        if (neverShowAgain) {
            //check if they have already registered first
            const isRegistered = localStorage.getItem(modalId) === "registered";
            if (isRegistered) return;
            localStorage.setItem(modalId, 'hidden');
        }
        return;
    }

    //see if a modal is already open
    if (openModals.length > 0) {
        queuedModals.push(modal);
        return;
    }

    //check if the modal was closed before
    const modalState = localStorage.getItem(modalId);
    if (modalState == 'hidden') {
        //if it was hidden, see if there is a gated content block, if so it should be force shown.
        const isGatedContent = checkIfGatedContentPage();
        if (isGatedContent) {
            toggleBackDrop(true, modal);
            modal.classList.add("show");
            openModals.push(modal);
            return;
        } 
    }

    //if no gated content blocks, simply see if it was hidden or registed before.
    if (['hidden', 'registered'].includes(modalState ?? '')) return;

    modal.classList.add('show');
    toggleBackDrop(true, modal);
    openModals.push(modal);
}

function bindCloseButton(modal: Element, removeCloseButton: boolean = false) {
    const closeButton = modal.querySelector('button.close-icon');
    if (!closeButton) return;
    if (removeCloseButton) {
        closeButton.remove();
        return;
    }
    closeButton.addEventListener('click', () => {
        toggleModal(modal, true, true);
    });
}

function toggleBackDrop(open: boolean, modal: Element) {
    const siteHeader = document.querySelector('header.site-header');
    const isGatedContentPage = checkIfGatedContentPage();
    if (modal.classList.contains('no-backdrop')) return;
    if (open) {
        const backdrop = document.createElement('div');
        backdrop.classList.add('modal-backdrop','sticky-premium-form-backdrop');
        document.body.appendChild(backdrop);

        backdrop.style.zIndex = '1080';

        if (isGatedContentPage) {
            return;
        }

        backdrop.addEventListener('click', () => {
            toggleModal(modal, true, false);
            backdrop.remove();
        });

        // bind to the site header to close the modal
        if (siteHeader) siteHeader.addEventListener("click", e => toggleModal(modal, true, false));
        return;
    }

    if (siteHeader) siteHeader.removeEventListener("click", e => toggleModal(modal, true, false));
    const backdrop = document.querySelector('.modal-backdrop.sticky-premium-form-backdrop');
    if (backdrop) backdrop.remove();
}

function moveDisclaimer(modal: Element) {
    //copy the disclaimer to under the last text box if it is a large-modal.
    const disclaimer = modal.querySelector('.subscribe-block__disclaimer');
    if (modal.classList.contains('large-modal') && disclaimer) {
        disclaimer.remove();
        const emailTextBox = modal.querySelector('.FormTextbox  .Email');
        emailTextBox?.insertAdjacentElement('afterend', disclaimer);
    }
}