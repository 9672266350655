declare global {
    interface Window {
        markSimpleStickyPremiumContentAsClosed: (modalId: string) => void;
    }
}
export function initialiseSimpleStickyPremiumContent(component: Element) {
    bindCloseButton(component);
    toggleModal(component);
}

function toggleModal(modal: Element, hide: boolean = false, neverShowAgain: boolean = false) {
    const modalId = modal.getAttribute('id') ?? new Date().getTime().toString();
    if (hide) {
        modal.classList.remove('show');
        //save in session storage, that the modal was closed.
        if (neverShowAgain) sessionStorage.setItem(modalId, 'closed');
        return;
    }
    //check if the modal was closed before
    const modalState = sessionStorage.getItem(modalId);
    if (modalState === 'closed') return;
    modal.classList.add('show');
    //create a backdrop
}

function bindCloseButton(modal: Element) {
    const closeButton = modal.querySelector('button.close-icon');
    if (!closeButton) return;
    closeButton.addEventListener('click', () => {
        toggleModal(modal, true, true);
    });
}

window.markSimpleStickyPremiumContentAsClosed = function (modalId: string) {
     const modal = document.getElementById(modalId);
    if (modal) toggleModal(modal, true, true);
}