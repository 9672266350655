import globalTranslationApiService from "./global-translation-api-service";
import { default as globalTranslationSectionProvider } from "./global-translation-section-provider";

const globalTranslationService = class {
    fallbackLanguage = 'en';
    fallbackCountry = null;

    translateKeys(keys) {
        if(!Array.isArray(keys)) {
            return Promise.resolve(keys);
        }

        const currentLanguage = this.getCurrentLanguage();
        const currentCountry = this.getCurrentCountry();

        let allSections = [];
        keys.forEach(key => {
            const sectionName = globalTranslationSectionProvider.getTranslationSection(key);
            allSections.push(sectionName);
        });

        allSections = this.distinctArray(allSections);

        const result = this.fetchChangedSectionHashes(allSections, currentLanguage, currentCountry)
            .then((hashChangedSections) => {

                const translatedKeys = {};
                let sectionsToFetchForCurrentLanguage = [];
                keys.forEach(key => {
                    const sectionName = globalTranslationSectionProvider.getTranslationSection(key);
                    const localStorageTranslation = this.tryGetTranslationFromLocalStorage(key, sectionName, currentLanguage, currentCountry);
                    if(localStorageTranslation === undefined) {
                        sectionsToFetchForCurrentLanguage.push(sectionName);
                    } else {
                        translatedKeys[key] = localStorageTranslation;
                    }
                });
                
                sectionsToFetchForCurrentLanguage = this.distinctArray(
                    sectionsToFetchForCurrentLanguage
                    .concat(hashChangedSections));

                if(sectionsToFetchForCurrentLanguage.length === 0) {
                    return Promise.resolve(translatedKeys);
                }
        
                const untranslatedKeysForCurrentLanguage = [];
                let sectionsToFetchFromFallbackLanguage = [];
                let allSectionsForFallbackLanguage = [];
                const result = this.fetchMultipleSections(sectionsToFetchForCurrentLanguage, currentLanguage, currentCountry)
                    .then(() => {
                        keys.forEach(key => {
                            const sectionName = globalTranslationSectionProvider.getTranslationSection(key);
                            const localStorageTranslation = this.tryGetTranslationFromLocalStorage(key, sectionName, currentLanguage, currentCountry);
                            if(localStorageTranslation === undefined) {
                                untranslatedKeysForCurrentLanguage.push(key);
                            } else {
                                translatedKeys[key] = localStorageTranslation;
                            }
                        });
        
                        untranslatedKeysForCurrentLanguage.forEach(key => {
                            const sectionName = globalTranslationSectionProvider.getTranslationSection(key);
                            const localStorageTranslation = this.tryGetTranslationFromLocalStorage(key, sectionName, this.fallbackLanguage, this.fallbackCountry);
                            if(localStorageTranslation === undefined) {
                                sectionsToFetchFromFallbackLanguage.push(sectionName);
                            } else {
                                translatedKeys[key] = localStorageTranslation;
                            }
                            allSectionsForFallbackLanguage.push(sectionName);
                        });
        
                        sectionsToFetchFromFallbackLanguage = this.distinctArray(sectionsToFetchFromFallbackLanguage);
                        allSectionsForFallbackLanguage = this.distinctArray(allSectionsForFallbackLanguage);
                    })
                    .then(() => {
                        const fallbackLanguageFetch = this.fetchChangedSectionHashes(allSectionsForFallbackLanguage, this.fallbackLanguage, this.fallbackCountry)
                            .then((hashChangedSectionsForFallbackLanguage) => {
                                sectionsToFetchFromFallbackLanguage = this.distinctArray(
                                    sectionsToFetchFromFallbackLanguage
                                    .concat(hashChangedSectionsForFallbackLanguage));
                
                                if(sectionsToFetchFromFallbackLanguage.length === 0) {
                                    return Promise.resolve(translatedKeys);
                                }
            
                                const fallbackLanguageFetch = this.fetchMultipleSections(sectionsToFetchFromFallbackLanguage, this.fallbackLanguage, this.fallbackCountry)
                                    .then(() => {
                                        untranslatedKeysForCurrentLanguage.forEach(key => {
                                            const sectionName = globalTranslationSectionProvider.getTranslationSection(key);
                                            const localStorageTranslation = this.tryGetTranslationFromLocalStorage(key, sectionName, this.fallbackLanguage, this.fallbackCountry);
                                            if(localStorageTranslation !== undefined) {
                                                translatedKeys[key] = localStorageTranslation;
                                            }
                                        });
                                    });
                
                                return fallbackLanguageFetch;
                            });

                        return fallbackLanguageFetch;
                    })
                    .then(() => {
                        return translatedKeys;
                    });
                
                return result;
            })

        return result;
    }

    translateKey(key) {
        const sectionName = globalTranslationSectionProvider.getTranslationSection(key);
        const currentLanguage = this.getCurrentLanguage();
        const currentCountry = this.getCurrentCountry();

        const result = this.hasSectionHashChanged(sectionName, currentLanguage, currentCountry)
            .then(sectionHashChanged => {
                const result = this.getTranslationFromSection(key, sectionName, currentLanguage, currentCountry, sectionHashChanged)
                .then(translation => {
                    if(translation !== undefined) {
                        return translation;
                    }

                    const fallbackTranslation = this.hasSectionHashChanged(sectionName, this.fallbackLanguage, this.fallbackCountry)
                        .then(fallbackSectionHashChanged => {
                            const fallbackTranslation = this.getTranslationFromSection(key, sectionName, this.fallbackLanguage, this.fallbackCountry, fallbackSectionHashChanged);
                            return fallbackTranslation;
                        });
    
                    return fallbackTranslation;
                });

                return result;
            });

        return result;
    }

    getTranslationFromSection(key, sectionName, language, country, forceFetch = false) {
        const result = this.getSectionByNameAndLanguage(sectionName, language, country, forceFetch)
            .then(result => {
                const translation = result.section[key];
                if(translation !== undefined || !result.cached) {
                    return translation;
                }

                const refetchedTranslation = this.getSectionByNameAndLanguage(sectionName, language, country, true)
                    .then(result => result.section[key]);
                return refetchedTranslation;
            });
        return result;
    }

    getSectionByNameAndLanguage(sectionName, language, country, forceFetch = false) {
        const sectionKey = this.getSectionName(sectionName, language, country);
        const sectionHashName = this.getSectionHashName(sectionName, language, country);

        if(!forceFetch)
        {
            const sectionJson = localStorage.getItem(sectionKey);
            if(sectionJson !== null) {
                const section = JSON.parse(sectionJson);
                return Promise.resolve({
                    cached: true,
                    section: section
                });
            }
        }

        const fetchPromise = globalTranslationApiService
            .fetchSection(sectionName, language, country)
            .then(data => {
                localStorage.setItem(sectionKey, JSON.stringify(data.translation));
                localStorage.setItem(sectionHashName, data.hash);
                return {
                    cached: false,
                    section: data
                };
            });
        return fetchPromise;
    }

    fetchMultipleSections(sectionNames, language, country) {
        const fetchPromise = globalTranslationApiService
            .fetchSections(sectionNames, language, country)
            .then(data => {
                var translations = data.translations;
                for (var key in translations) {
                    if (translations.hasOwnProperty(key)) {    
                        const sectionName = this.getSectionName(key, language, country);
                        if(translations[key] !== null && translations[key] !== "null") {
                            const jsonSection = JSON.stringify(translations[key]);
                            localStorage.setItem(sectionName, jsonSection);
                        }
                    }
                }

                var hashes = data.hashes;
                for(var key in hashes) {
                    if (hashes.hasOwnProperty(key)) {    
                        const sectionHashName = this.getSectionHashName(key, language, country);
                        if(hashes[key] !== null && hashes[key] !== "null") {
                            const fetchedHashForSection = hashes[key];
                            localStorage.setItem(sectionHashName, fetchedHashForSection);
                        }
                    }
                }
            });
        return fetchPromise;
    }

    hasSectionHashChanged(sectionName, language, country) {
        const fetchPromise = globalTranslationApiService
            .fetchSectionHash(sectionName, language, country)
            .then(hash => {
                const sectionHashName = this.getSectionHashName(sectionName, language, country);
                const currentHashForSection = localStorage.getItem(sectionHashName);
                return currentHashForSection !== hash;
            });
        return fetchPromise;
    }

    fetchChangedSectionHashes(sectionNames, language, country) {
        if(sectionNames.length === 0) {
            return Promise.resolve([]); 
        }

        const fetchPromise = globalTranslationApiService
            .fetchSectionsHashes(sectionNames, language, country)
            .then(data => {
                let hashChangedSections = [];
                for (var key in data) {
                    if (data.hasOwnProperty(key)) {    
                        const sectionHashName = this.getSectionHashName(key, language, country);
                        if(data[key] !== null && data[key] !== "null") {
                            const currentHashForSection = localStorage.getItem(sectionHashName);
                            const fetchedHashForSection = data[key];
                            if(currentHashForSection !== fetchedHashForSection) {
                                hashChangedSections.push(key);
                            }
                        }
                    }
                }
                return hashChangedSections;
            });
        return fetchPromise;
    }

    tryGetTranslationFromLocalStorage(key, sectionName, language, country) {
        const sectionKey = this.getSectionName(sectionName, language, country);
        const sectionJson = localStorage.getItem(sectionKey);
        if(sectionJson !== null && sectionJson !== "null") {
            const section = JSON.parse(sectionJson);
            const translation = section[key];
            return translation;
        }

        return undefined;
    }

    getSectionName(sectionName, language, country) {
        return `translation-${sectionName}-${language}-${country}`;
    }

    getSectionHashName(sectionName, language, country) {
        return `translation-${sectionName}-${language}-${country}-hash`;
    }

    getCurrentLanguage() {
        const lang = this.getLang();
        const currentLanguage = lang.split('-')[0];
        return currentLanguage;
    }

    getCurrentCountry() {
        const lang = this.getLang();
        const currentCountry = lang.split('-')[1];
        return currentCountry;
    }

    getLang() {
        const [html] = document.getElementsByTagName("html")
        const lang = html.getAttribute("lang");
        return lang;
    }

    distinctArray(array) {
        return array.filter((v, i, a) => a.indexOf(v) === i);
    }
}

export default new globalTranslationService();