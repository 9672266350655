export const loadScripts = async () => {
    // Jump Links
    if (document.querySelector(".look-for-jumplinks .jump-link-nav") || document.querySelector(".page-class-insights .jump-link-nav")) {
        await import("../../../../us/js/src/insights/populate-jumplink-nav.js");
    }

    // Highcharts Block
    if (document.querySelector(".highcharts-block-js")) {
        await import("../../../../us/js/src/highcharts-block.js");
    }

    // Performance Charts (highcharts)
    if (document.querySelector(".performance-chart-block-js")) {
        await import("../../../../eu/js/src/sources/performance-charts.js");
    }
};