(function ($) {
    //return if the class added to hide the alert bar
    if ($('body.hide-promo-banner').length > 0) return;


    var debug = false;

    var headerHeight = $("header.site-header").outerHeight();

    //debugger;

    function showNotificationBanners() {
        $.ajax({
            type: 'GET',
            url: '/banners/get',
            success: function (items) {
                if (debug) {
                    console.log('Get notification banners:');
                    console.log(items);
                }
                banners = items;
                getNotificationBannersIdsToDisplayAndShow(items);
            }
        });
    }

    function adjustHeaderHeight() {
        var AlertBArsCumilativeHeights = $(".alert-bar").toArray().reduce((prev, curr) => { return $(curr).height() + prev }, 0)
        $("#root > .sticky-wrapper").height(headerHeight + AlertBArsCumilativeHeights);
        $("body > .bs4-nav:first").height(headerHeight + AlertBArsCumilativeHeights);
    }

    function getNotificationBannersIdsToDisplayAndShow(notificationBanners) {

        var bannerInfoForFiltering = notificationBanners;

        if (bannerInfoForFiltering.length) {
            $.ajax({
                type: 'POST',
                url: '/banners/display',
                data: {
                    timeZoneOffset: new Date().getTimezoneOffset(),
                    bannerInfoForFiltering: bannerInfoForFiltering
                },
                success: function (idsToDisplay) {
                    if (debug) { console.log('Display banners:' + idsToDisplay); }

                    notificationBanners.forEach(item => {
                        if (!idsToDisplay.includes(item.Id)) return;
                        //get the ID of the webinar and inject accordingly.
                        var markup = item.Markup.replace("{id-of-the-webinar}", item.Id)

                        //get the speakers and stringify them.
                        var speakers = item.Speakers && item.Speakers.length > 0 ? item.Speakers.reduce((prev, curr) => prev.concat(", ",curr)) : "";

                        //replace the speakers
                        markup = markup.replace("{Speakers}", speakers)


                        //The webinar link is returned as a button, so we need to do some processing to get the link off the button and inject it as the primary link.
                        var linkbutton = $(markup).find('.particiapnt-info-content button');
                        var link = "#";
                        if (linkbutton.length > 0) {
                            link = $(linkbutton).attr("href") || $(linkbutton).data("href")
                        }
                        //if the service responds with a link, then that takes precedance...
                        link = item.WebinarLink && item.WebinarLink != "" ? item.WebinarLink : link;

                        //add the link to the link placeholder...
                        markup = markup.replace("{primary-link}", link)
                        
                        //see if the webinar has started, if not remove the icon... ...using some jQuery jugglery...
                        markup = item.IsStarted == false ? markup.replace($(markup).find(".alert-bar__left svg")[0].outerHTML,"") : markup.replace("Upcoming Webinar", "Live Webinar"); 

                        //render the banner
                        rnBannerMarkup(markup);
                    });

                    //bind listeners
                    $('.alert-bar .js-register-now').click(registerHandler);
                    $('.alert-bar .js-no-thanks').click(noThanksHandler);
                }
            });
        }
    }


    function updateBannerState(id, state, goToUrl) {
        $.ajax({
            type: 'POST',
            url: '/banners/set',
            data: {
                id: id,
                state: state
            },
            success: function () {

                if (goToUrl) {
                    location.href = goToUrl;
                }
                $('.alert-bar').remove();
                getNotificationBannersIdsToDisplayAndShow(banners);
                adjustHeaderHeight();
            }
        });
    }

    function registerHandler() {
        var button = $(this),
            bannerContainer = button.closest('[data-item-id]'),
            webinarId = bannerContainer.attr('data-item-id'),
            state = 'Registered',
            registerPageUrl = button.attr('data-href');

        updateBannerState(webinarId, state, registerPageUrl);
    }

    function noThanksHandler() {
        var button = $(this),
            bannerContainer = button.closest('[data-item-id]'),
            webinarId = bannerContainer.attr('data-item-id'),
            state = 'Closed';

        updateBannerState(webinarId, state);
        button.closest('.alert-bar').hide();
    }

    function rnBannerMarkup(markup) {
        $('header.site-header').append(markup);
        adjustHeaderHeight();

    }

    $(function () {
        var isAdmin = window.location.href.toLocaleLowerCase().indexOf("admin") > -1;

        if (!isAdmin) {
            showNotificationBanners();

        }
    });
})(jQuery);
