
// Highlisht the items on the top menu
function highlightTopMenu() {

    var fundItemSelector = 'a[href="' + window.location.pathname + '"].fund-link';
    var ctaItemSelector = 'a[href="' + window.location.pathname + '"].cta-link';
    var subMenuItemSelector = 'a[href="' + window.location.pathname + '"].site-header__submenu-action';
    var mobileSubMenuItemSelector = 'a[href="' + window.location.pathname + '"].site-header__mobile-submenu-action';

    var activeMenuElements = $(fundItemSelector + ', ' + ctaItemSelector + ', ' + subMenuItemSelector + ', ' + mobileSubMenuItemSelector);

    if (activeMenuElements.length !== 0) {
        $(activeMenuElements).each(function (i, el) {
            var topMenuElement = $(el).closest('.site-header__nav-item.nav-item').find('a').first();

            $(topMenuElement).addClass('active');
        });
    }
}

window.addEventListener("DOMContentLoaded", highlightTopMenu);

function updatePageVistCountOnLocalStorage() {
    var pageVisitCount = localStorage.getItem("ve-PageVisitCount-corp");
    if (pageVisitCount) {
        pageVisitCount = parseInt(pageVisitCount) + 1;
    } else {
        //if gateway modal is open, ignore the page visit
        const gatewayModal = document.getElementById("gateway-modal");
        if(gatewayModal && gatewayModal.classList.contains("show")) return;
        pageVisitCount = 1;
    }
    localStorage.setItem("ve-PageVisitCount-corp", pageVisitCount);
}

window.addEventListener("DOMContentLoaded", updatePageVistCountOnLocalStorage);

function menuScripts() {

    /**
     * Remove and replace all of the submen items to remove all eventlistners added by VShift.
     * This is replaced by our own hover function below.
     * Done so we can keep their scripts.js file intact.
     */
    $('.site-header__submenu-action').each(function (i, e) {
        var elClone = e.cloneNode(true);
        e.parentNode.replaceChild(elClone, e);
    });

    /** Non-investment submenu items --> make sure they are activated on hover. */
    $('.site-header__submenu .site-header__submenu-action.list-group-item').hover(function (e) {
        //get the data values
        var menu = $(this).data('menu');
        var targetPane = $(this).data('default');

        //make this active
        $('.site-header__submenu#' + menu + ' .site-header__submenu-action.list-group-item').removeClass('active').removeClass('show');
        $(this).tab('show').addClass('active show');

        //Toggle the actual menu now
        $('#' + menu).find(".tab-content > .tab-pane").removeClass("active show");
        $('#' + menu).find("#" + targetPane).addClass("active show");

    });

    //Convert the megamenu tabs in the investments section to work as tab on hover.
    hoverfytabs('.navbar .investments-tabs > .nav-tabs > li', false);
    //hoverfytabs('.bs4-nav .site-header__submenu-inner .list-group', false);

    //Disable GPU rendering in Popper for IE11. Fixes blurryness, rending issues etc.
    Popper.default.Defaults.modifiers.computeStyle.gpuAcceleration =
        (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > -1) ?
            false : Popper.default.Defaults.modifiers.computeStyle.gpuAcceleration;
}

window.addEventListener("DOMContentLoaded", menuScripts);




/*** These are a duplicate of static/js/header/header-override.js
 *      Make sure they are modified there too! ***/

/*** converts a bs4 tab-nav to render on hover vs click ***/
hoverfytabs = function (selector, hoverblock) {
    hoverblock = (!hoverblock) ? false : hoverblock;

    $(selector).hover(function () {
        if ($(this).hasClass('hoverblock'))
            return;
        else
            /*force remove everything else*/
            targettedTabGroup = $(this).parent().find('a.active').data('target')
        $(targettedTabGroup).parent().find('.active.show').removeClass('active').removeClass('show')
        $(this).parent().find('a').removeClass('active').removeClass('show')
        $(this).find('a').tab('show');
    });
    if (hoverblock === true) {
        $(selector).find('a').click(function () {
            $(this).parent()
                .siblings().addClass('hoverblock');
        });

    }

}



/***
 * Sticky-secondary nav code
 */
window.stickywaypoint = {
    sticky: function () {
        return $('#sticky-waypoint').hasClass("sticky");
    },
    detachWaypoint: function () {
        //Remove all Vshift code
        var elem = document.getElementById('sticky-waypoint');
        elem.parentNode.replaceChild(elem.cloneNode(true), elem);
    },
    checkIntersection: function () {
        var head = $(".site-header")[0].getBoundingClientRect().bottom;
        var hero = $(".hero__content-container").length > 0 ? $(".hero__content-container")[0].getBoundingClientRect().bottom : 0;
        return head - hero;
    },
    toggle: function (makeSticky) {
        if (makeSticky) { $('#sticky-waypoint').addClass('sticky'); return; }
        $('#sticky-waypoint.sticky').removeClass('sticky');
    },
    doScroll: function () {
        if ((stickywaypoint.checkIntersection() > -20) && !stickywaypoint.sticky()) { stickywaypoint.toggle(true); return; }
        if ((stickywaypoint.checkIntersection() < -19) && stickywaypoint.sticky()) { stickywaypoint.toggle(false); return; }
    },
    init: function () {
        $('#sticky-waypoint').addClass('loaded');
        stickywaypoint.detachWaypoint();
        $(window).scroll(stickywaypoint.doScroll);
    }
}

window.addEventListener("load", function () {
    //Guard: only run these scripts if the page contains a scrollspy.
    if ($("#sticky-waypoint.secondary-nav").length < 1) return;

    //see if the URL has a querystring and manually scroll up 160px to offsef for the sticky nav.
    window.location.hash.length > 3 ? window.scrollBy(0, -160) : false;

    //Initialise the above.
    stickywaypoint.init();

    //Sticky tends to erroneously stick, remove it as soon as page loads only if its not a insights page.
    if ($(".page-class-insights #sticky-waypoint").length == 0) {
        window.setTimeout(function () { $('#sticky-waypoint').removeClass('sticky'); }, 100);
    }
});



/*
 * Sticky back-to-top
 */
window.stickyBackToTop = {
    init: function () {

        if ($('#back-to-top-wrapper > .back-to-top').length === 0) return;

        var $btt = $('#back-to-top-wrapper > .back-to-top');
        var $win = $(window);
        var height = $win.height() / 4;

        $win.scroll(function (e) {
            $win.scrollTop() > height ? $btt.show() : $btt.hide();
        });

    }
}
window.addEventListener('DOMContentLoaded', function () {
    stickyBackToTop.init();
});



window.addEventListener('DOMContentLoaded', function () {
    /*
* Sticky head - remove shadow and add back when scrolled
*/
    $(window).scroll(function () {
        var scroll = $(window).scrollTop();

        if (scroll >= 100) {
            $(".site-header").removeClass("no-drop-shadow");
        }
        else {
            $(".site-header").addClass("no-drop-shadow");
        }
    });

    /**
 * Mega-menu event handler to prevent being overridden by a dropdown.
 */
    $("li.site-header__nav-item.nav-item").hover(function () {
        $(".dropdown-menu.show").removeClass("show");
        $(".dropdown.show, .dropdown-checkbox.show").removeClass("show")
    });
});

//triggers the print button on the footer...
window.printPage = function() {
    //add any other print conditions here later on... (e.g. for specific factsheet etc.)
    window.print();
}

window.addEventListener('DOMContentLoaded', function () {
    //add the relevant spacing for the blocks with the IDs
    window.GenerateAnchoredBlocks = (isBlog) => {
        var siteHeaderHeight = $(".mobile-push .site-header").height() + 75 ?? $(".site-header").height() + 50;
        $('.anchored-block').each(function (id) {
            if (isBlog) {
                $(this).removeAttr("style");
            }
            let marginTop = parseInt($(this).css("margin-top"));
            let paddingTop = parseInt($(this).css("padding-top"));
            let newPaddingTop = siteHeaderHeight + paddingTop;
            let newMarginTop = marginTop - siteHeaderHeight;
            let newInlineStyles = ';padding-top:' + newPaddingTop + 'px !important; margin-top: ' + newMarginTop + 'px !important';
            let style = $(this).attr('style');
            style += newInlineStyles;
            //console.log(style);
            $(this).attr('style', style);
            //$(window).scrollTop(y + 150);
        })
        $('[data-spy="scroll"]').each(function () {
            $(this).scrollspy('refresh')
        })
    }

    if ($('.anchored-block')) GenerateAnchoredBlocks(false);
});