//make jQuery accessible globally.
window.jQuery = require('jquery');
window.$ = jQuery;

window.Popper = require('popper.js');
Popper.default.Defaults.modifiers.computeStyle.gpuAcceleration = false

require('bootstrap');
require('slick-carousel');

const { debounce } = require('lodash');

const waypoints = require('./waypoints.js');
const { isInsideElement } = require('../lib/utils/dom-utils.js');
const { scrollToTop } = require('../lib/utils/scroll-utils.js');
const { default: globalTranslationService } = require('../../../../js/global-translation/global-translation-service.js');
window.globalTranslationService = globalTranslationService;

import { loadScripts } from './index-module.js';

window.addEventListener("DOMContentLoaded", () => {
    (async () => {
        await loadScripts();
    })();
})

/* eslint-disable no-undef */
$(function() {
  // Carousels
  const sliderFor = $('.slider-for');

  $(window).on('resize', function() {
    if (sliderFor.length) sliderFor[0].slick.refresh();
  });
  
  if (sliderFor.length) sliderFor.slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: 'ondemand',
    arrows: false,
    dots: true,
    draggable: false,
    swipe: false,
    appendDots: '.compound-hero__slick-dots',
    responsive: [
      {
        breakpoint: 992,
        settings: {
          draggable: true,
          swipe: true,
        },
      },
    ],
  });

  /* eslint-disable max-len */
  $('.recommended-insights__list').each(function(id) {
    const slider = $(this);
    this.id = `recommended-insights-list-${id}`;

    const actions = $(`.recommended-insights__actions-${id}`);
    const counts = actions.find('.recommended-insights__counts');
    const arrows = actions.find('.recommended-insights__arrows');

    if (id === 0) actions.css('display', 'block');
    else actions.css('display', 'none');

    slider.on('init reInit afterChange', function(_, slick, currentSlide) {
      const currentSlideNumber = currentSlide ? currentSlide : 0;
      const i = currentSlideNumber + 1;
      const slidesToShow = slick.options.slidesToShow;
      if (slidesToShow > 1) {
        counts.html(`<b>${i}</b> - <b>${currentSlideNumber + slidesToShow}</b> of <span>${slick.slideCount}</span>`);
      }
      else {
        counts.html(`<b>${i}</b> of <span>${slick.slideCount}</span>`);
      }
    });

    slider.slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      lazyLoad: 'ondemand',
      infinite: false,
      arrows: true,
      appendArrows: arrows,
      prevArrow: `
        <button class="recommended-insights__carousel-control recommended-insights__carousel-control--prev" type="button" aria-label="Scroll left">
        <svg
          aria-hidden="true"
          focusable="false"
          data-prefix="far"
          data-icon="chevron-left"
          class="svg-inline--fa fa-chevron-left fa-w-16 fa-lg icon"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 18 18"
        >
          <path
            fill="currentColor"
            d="M13.13 2.26a.75.75 0 00-.22-.54.75.75 0 00-1.07 0L5.1 8.47a.75.75 0 000 1.06l6.75 6.75a.75.75 0 001.07 0 .75.75 0 000-1.06L6.69 9l6.22-6.22a.75.75 0 00.21-.52z"
          >
          </path>
        </svg>
      </button>
    `,
      nextArrow: `
      <button class="recommended-insights__carousel-control recommended-insights__carousel-control--next" type="button" aria-label="Scroll right">
        <svg
          aria-hidden="true"
          focusable="false"
          data-prefix="far"
          data-icon="chevron-right"
          class="svg-inline--fa fa-chevron-right fa-w-16 fa-lg icon"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 18 18"
        >
          <path
            fill="currentColor"
            d="M4.87 15.74a.75.75 0 00.22.54.75.75 0 001.07 0l6.75-6.75a.75.75 0 000-1.06L6.16 1.72a.75.75 0 00-1.07 0 .75.75 0 000 1.06L11.31 9 5.1 15.22a.75.75 0 00-.22.52z"
          >
          </path>
        </svg>
      </button>
    `,
      mobileFirst: true,
      responsive: [
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
      ],
    });
  });

  $('.recommended-insights .tab-nav__link').on('shown.bs.tab', function() {
    const id = $(this).index('.recommended-insights .tab-nav__link');
    $('.tab-nav__list li').each(function(index) {
      $(`.recommended-insights__actions-${index}`).css('display', 'none');
    });
    $(`.recommended-insights__actions-${id}`).css('display', 'block');
  });


  $('.carousel-block__main--desktop').each(function(id) {
    const slider = $(this);
    this.id = `carousel-block-${id}`;

    slider.on('init reInit afterChange', function(_, slick, currentSlide) {
      const currentSlideNumber = currentSlide ? currentSlide : 0;
      const i = currentSlideNumber + 1;
      const slidesToShow = slick.options.slidesToShow;
      const el = $(`#carousel-block-${id}`).prev().find(`.carousel-block__main--desktop-count`);
      if (slidesToShow > 1) {
        el.html(`<b>${i}</b> - <b>${currentSlideNumber + slidesToShow}</b> of <span>${slick.slideCount}</span>`);
      }
      else {
        el.html(`<b>${i}</b> of <span>${slick.slideCount}</span>`);
      }

      // check to see if there are one or less slides
      if (slick.slideCount < slidesToShow) {
        slider.find('.slick-dots').hide();
      }
    });

    slider.removeClass("d-none");

    slider.slick({
      slidesToShow: 3,
      slidesToScroll: 3,
      infinite: true,
      arrows: true,
      dots: true,
      appendArrows: $('.carousel-block__control-container')[id],
      prevArrow: `
        <button class="carousel-block__control carousel-block__control--prev" type="button" aria-label="Scroll left">
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="far"
            data-icon="chevron-left"
            class="svg-inline--fa fa-chevron-left fa-w-16 fa-3x icon"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 18 18"
          >
            <path
              fill="currentColor"
              d="M13.13 2.26a.75.75 0 00-.22-.54.75.75 0 00-1.07 0L5.1 8.47a.75.75 0 000 1.06l6.75 6.75a.75.75 0 001.07 0 .75.75 0 000-1.06L6.69 9l6.22-6.22a.75.75 0 00.21-.52z"
            >
            </path>
          </svg>
        </button>
      `,
      nextArrow: `
        <button class="carousel-block__control carousel-block__control--next" type="button" aria-label="Scroll right">
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="far"
            data-icon="chevron-right"
            class="svg-inline--fa fa-chevron-right fa-w-16 fa-3x icon"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 18 18"
          >
            <path
              fill="currentColor"
              d="M4.87 15.74a.75.75 0 00.22.54.75.75 0 001.07 0l6.75-6.75a.75.75 0 000-1.06L6.16 1.72a.75.75 0 00-1.07 0 .75.75 0 000 1.06L11.31 9 5.1 15.22a.75.75 0 00-.22.52z"
            >
            </path>
          </svg>
        </button>
      `,
      mobileFirst: true,
      responsive: [
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
      ],
    });
  });

  $('.fund-carousel-block__main--desktop').each(function(id) {
    const slider = $(this);
    this.id = `fund-carousel-block-${id}`;

            /*
       * Count calc for Fund Carousel's now driven from overrides
       * Refer override/education.js
       */
    //slider.on('init reInit afterChange', function(_, slick, currentSlide) {
    //  const currentSlideNumber = currentSlide ? currentSlide : 0;
    //  const i = currentSlideNumber + 1;
    //  const slidesToShow = slick.options.slidesToShow;
    //  const el = $(`#fund-carousel-block-${id}`).prev().find(`.fund-carousel-block__main--desktop-count`);
    //  if (slidesToShow > 1) {
    //    el.html(`<b>${i}</b> - <b>${currentSlideNumber + slidesToShow}</b> of <span>${slick.slideCount}</span>`);
    //  }
    //  else {
    //    el.html(`<b>${i}</b> of <span>${slick.slideCount}</span>`);
    //  }
    //});

       $('.fund-carousel-block__main--desktop.d-none.delayed-carousel').removeClass("d-none");

    slider.slick({
      rows:0,
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: false,
      arrows: true,
      appendArrows: $('.fund-carousel-block__control-container')[id],
      prevArrow: `
        <button class="fund-carousel-block__control fund-carousel-block__control--prev" type="button" aria-label="Scroll left">
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="far"
            data-icon="chevron-left"
            class="svg-inline--fa fa-chevron-left fa-w-16 fa-lg icon"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 18 18"
          >
            <path
              fill="currentColor"
              d="M13.13 2.26a.75.75 0 00-.22-.54.75.75 0 00-1.07 0L5.1 8.47a.75.75 0 000 1.06l6.75 6.75a.75.75 0 001.07 0 .75.75 0 000-1.06L6.69 9l6.22-6.22a.75.75 0 00.21-.52z"
            >
            </path>
          </svg>
        </button>
      `,
      nextArrow: `
        <button class="fund-carousel-block__control fund-carousel-block__control--next" type="button" aria-label="Scroll right">
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="far"
            data-icon="chevron-right"
            class="svg-inline--fa fa-chevron-right fa-w-16 fa-lg icon"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 18 18"
          >
            <path
              fill="currentColor"
              d="M4.87 15.74a.75.75 0 00.22.54.75.75 0 001.07 0l6.75-6.75a.75.75 0 000-1.06L6.16 1.72a.75.75 0 00-1.07 0 .75.75 0 000 1.06L11.31 9 5.1 15.22a.75.75 0 00-.22.52z"
            >
            </path>
          </svg>
        </button>
      `,
      mobileFirst: true,
      responsive: [
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
      ],
    });
  });

  $('.carousel-wrapper__main--desktop').each(function(id) {
    const slider = $(this);
    this.id = `carousel-wrapper-${id}`;

    slider.on('init reInit afterChange', function(_, slick, currentSlide) {
      const currentSlideNumber = currentSlide ? currentSlide : 0;
      const i = currentSlideNumber + 1;
      const slidesToShow = slick.options.slidesToShow;
      var carousel = $(`#carousel-wrapper-${id}`).prev();
      const el = carousel.find(`.carousel-wrapper__main--desktop-count`);
      if (slidesToShow > 1 && slick.slideCount > 1 && slidesToShow > slick.slideCount) {
            el.html(`<b>${i}</b> - <b>${currentSlideNumber + slick.slideCount}</b> of <span>${slick.slideCount}</span>`);
      }
      else if (slidesToShow > 1 && slick.slideCount > 1) {
        el.html(`<b>${i}</b> - <b>${currentSlideNumber + slidesToShow}</b> of <span>${slick.slideCount}</span>`);
      }
      else {
        el.html(`<b>${i}</b> of <span>${slick.slideCount}</span>`);
      }
      carousel.find("button.carousel-wrapper__control[aria-disabled='false']").attr("data-ve-gtm", "arrow-more");
      carousel.find("button.carousel-wrapper__control[aria-disabled='true']").removeAttr("data-ve-gtm");
    });

     $('.carousel-wrapper__main--desktop.d-none.delayed-carousel').removeClass("d-none");

      slider.slick({
      rows: 0,
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: false,
      arrows: true,
      appendArrows: $('.carousel-wrapper__control-container')[id],
      prevArrow: `
        <button class="carousel-wrapper__control carousel-wrapper__control--prev" type="button" aria-label="Scroll left">
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="far"
            data-icon="chevron-left"
            class="svg-inline--fa fa-chevron-left fa-w-16 fa-lg icon"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 18 18"
          >
            <path
              fill="currentColor"
              d="M13.13 2.26a.75.75 0 00-.22-.54.75.75 0 00-1.07 0L5.1 8.47a.75.75 0 000 1.06l6.75 6.75a.75.75 0 001.07 0 .75.75 0 000-1.06L6.69 9l6.22-6.22a.75.75 0 00.21-.52z"
            >
            </path>
          </svg>
        </button>
      `,
      nextArrow: `
        <button class="carousel-wrapper__control carousel-wrapper__control--next" type="button" aria-label="Scroll right">
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="far"
            data-icon="chevron-right"
            class="svg-inline--fa fa-chevron-right fa-w-16 fa-lg icon"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 18 18"
          >
            <path
              fill="currentColor"
              d="M4.87 15.74a.75.75 0 00.22.54.75.75 0 001.07 0l6.75-6.75a.75.75 0 000-1.06L6.16 1.72a.75.75 0 00-1.07 0 .75.75 0 000 1.06L11.31 9 5.1 15.22a.75.75 0 00-.22.52z"
            >
            </path>
          </svg>
        </button>
      `,
      mobileFirst: true,
      responsive: [
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
      ],
    });
  });

        $('.more-from-category__main--desktop').each(function (id) {
        const slider = $(this);
        this.id = `more-from-category-${id}`;

        slider.on('init reInit afterChange', function (_, slick, currentSlide) {
            const currentSlideNumber = currentSlide ? currentSlide : 0;
            const i = currentSlideNumber + 1;
            const slidesToShow = slick.options.slidesToShow;
            var carousel = $(`#more-from-category-${id}`).prev();
            const el = carousel.find(`.more-from-category__main--desktop-count`);
            if (slidesToShow > 1 && slick.slideCount > 1 && slidesToShow > slick.slideCount) {
                el.html(`<b>${i}</b> - <b>${currentSlideNumber + slick.slideCount}</b> of <span>${slick.slideCount}</span>`);
            }
            else if (slidesToShow > 1 && slick.slideCount > 1) {
                el.html(`<b>${i}</b> - <b>${currentSlideNumber + slidesToShow}</b> of <span>${slick.slideCount}</span>`);
            }
            else {
                el.html(`<b>${i}</b> of <span>${slick.slideCount}</span>`);
            }
            carousel.find("button.more-from-category__control[aria-disabled='false']").attr("data-ve-gtm", "arrow-more");
            carousel.find("button.more-from-category__control[aria-disabled='true']").removeAttr("data-ve-gtm");
        });

        $('.more-from-category__main--desktop.d-none.delayed-carousel').removeClass("d-none");
        slider.slick({
            rows:0,
            slidesToShow: 1,
            accessibility: false,
            slidesToScroll: 1,
            infinite: false,
            autoplay: false,
            arrows: false,
            appendArrows: $('.more-from-category__control-container')[id],
            prevArrow: `
        <button class="more-from-category__control more-from-category__control--prev" type="button" aria-label="Scroll left">
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="far"
            data-icon="chevron-left"
            class="svg-inline--fa fa-chevron-left fa-w-16 fa-lg icon"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 18 18"
          >
            <path
              fill="currentColor"
              d="M13.13 2.26a.75.75 0 00-.22-.54.75.75 0 00-1.07 0L5.1 8.47a.75.75 0 000 1.06l6.75 6.75a.75.75 0 001.07 0 .75.75 0 000-1.06L6.69 9l6.22-6.22a.75.75 0 00.21-.52z"
            >
            </path>
          </svg>
        </button>
      `,
            nextArrow: `
        <button class="more-from-category__control more-from-category__control--next" type="button" aria-label="Scroll right">
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="far"
            data-icon="chevron-right"
            class="svg-inline--fa fa-chevron-right fa-w-16 fa-lg icon"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 18 18"
          >
            <path
              fill="currentColor"
              d="M4.87 15.74a.75.75 0 00.22.54.75.75 0 001.07 0l6.75-6.75a.75.75 0 000-1.06L6.16 1.72a.75.75 0 00-1.07 0 .75.75 0 000 1.06L11.31 9 5.1 15.22a.75.75 0 00-.22.52z"
            >
            </path>
          </svg>
        </button>
      `,
            mobileFirst: true,
            responsive: [
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                    },
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        arrows: true,
                    },
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        arrows: true,
                    },
                },
                {
                    breakpoint: 320,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        arrows: true,
                    },
                },
            ],
        });
    });

    $('.latest-digital-grid__main--desktop').each(function (id) {
        const slider = $(this);
        this.id = `latest-digital-grid-${id}`;

        slider.on('init reInit afterChange', function (_, slick, currentSlide) {
            const currentSlideNumber = currentSlide ? currentSlide : 0;
            const i = currentSlideNumber + 1;
            const slidesToShow = slick.options.slidesToShow;
            var carousel = $(`#latest-digital-grid-${id}`).prev();
            const el = carousel.find(`.latest-digital-grid__main--desktop-count`);
            if (slidesToShow > 1) {
                el.html(`<b>${i}</b> - <b>${currentSlideNumber + slidesToShow}</b> of <span>${slick.slideCount}</span>`);
            }
            else {
                el.html(`<b>${i}</b> of <span>${slick.slideCount}</span>`);
            }
            carousel.find("button.latest-digital-grid__control[aria-disabled='false']").attr("data-ve-gtm", "arrow-more");
            carousel.find("button.latest-digital-grid__control[aria-disabled='true']").removeAttr("data-ve-gtm");
        });

        slider.slick({
            slidesToShow: 1,
            rows:0,
            accessibility: false,
            slidesToScroll: 1,
            infinite: false,
            autoplay: false,
            arrows: false,
            appendArrows: $('.latest-digital-grid__control-container')[id],
            prevArrow: `
        <button class="latest-digital-grid__control latest-digital-grid__control--prev" type="button" aria-label="Scroll left">
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="far"
            data-icon="chevron-left"
            class="svg-inline--fa fa-chevron-left fa-w-16 fa-lg icon"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 18 18"
          >
            <path
              fill="currentColor"
              d="M13.13 2.26a.75.75 0 00-.22-.54.75.75 0 00-1.07 0L5.1 8.47a.75.75 0 000 1.06l6.75 6.75a.75.75 0 001.07 0 .75.75 0 000-1.06L6.69 9l6.22-6.22a.75.75 0 00.21-.52z"
            >
            </path>
          </svg>
        </button>
      `,
            nextArrow: `
        <button class="latest-digital-grid__control latest-digital-grid__control--next" type="button" aria-label="Scroll right">
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="far"
            data-icon="chevron-right"
            class="svg-inline--fa fa-chevron-right fa-w-16 fa-lg icon"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 18 18"
          >
            <path
              fill="currentColor"
              d="M4.87 15.74a.75.75 0 00.22.54.75.75 0 001.07 0l6.75-6.75a.75.75 0 000-1.06L6.16 1.72a.75.75 0 00-1.07 0 .75.75 0 000 1.06L11.31 9 5.1 15.22a.75.75 0 00-.22.52z"
            >
            </path>
          </svg>
        </button>
      `,
            mobileFirst: true,
            responsive: [
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 1,
                    },
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        arrows: true,
                    },
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        arrows: true,
                    },
                },
                {
                    breakpoint: 320,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        arrows: true,
                    },
                },
            ],
        });
    });

  // SiteHeader
  $(document).on('click.bs.dropdown.data-api', '.site-header__mobile-submenu', (event) => {
    if (!isInsideElement(event, 'a') && !isInsideElement(event, 'button')) {
      event.stopPropagation();
    }
  });

  $(document).on('click.bs.dropdown.data-api', '.site-header__mobile-submenu-toggle', () => {
    scrollToTop();
  });

  $(document).on('click.bs.dropdown.data-api', '.site-header__submenu .list-group-item-action', (event) => {
    event.stopPropagation();
  });

  $(document).on('click.bs.dropdown.data-api', '.site-header__submenu', (event) => {
    if (!isInsideElement(event, 'a') && !isInsideElement(event, 'button')) {
      event.stopPropagation();
    }
  });

  $('.site-header__submenu-action').on('mouseenter', (event) => {
    const actionElement = $(event.target),
          menuId = actionElement.attr('data-menu'),
          descriptionId = actionElement.attr('data-description'),
          currentActiveAction = $('#' + menuId + ' .list-group .list-group-item.active'),
          currentActivePane = $('#' + menuId + ' .site-header__submenu-content .tab-pane.active'),
          targetPane = $('#' + menuId + ' .site-header__submenu-content .tab-pane#' + descriptionId);

    $('#' + menuId + ' .site-header__submenu-content').attr('data-pane', currentActiveAction.attr('data-default'));
    currentActivePane.removeClass('show active');
    targetPane.addClass('show active');
  });

  $('.site-header__submenu-action').on('mouseleave', (event) => {
    const actionElement = $(event.target),
          menuId = actionElement.attr('data-menu'),
          descriptionId = actionElement.attr('data-description'),
          currentActiveAction = $('#' + menuId + ' .list-group .list-group-item.active'),
          currentActiveActionTarget = currentActiveAction.attr('data-default'),
          currentActivePane = $('#' + menuId + ' .site-header__submenu-content .tab-pane#' + descriptionId),
          newActivePane = $('#' + menuId + ' .site-header__submenu-content .tab-pane#' + currentActiveActionTarget);

    currentActivePane.removeClass('show active');
    newActivePane.addClass('show active');
  });

  $('.site-header .navbar-toggler').on('click', (event) => {
    const toggleElement = $(event.target),
          targetId = toggleElement.attr('data-target'),
          targetElement = $(targetId);

    toggleElement.toggleClass('collapsed');
    targetElement.toggleClass('show');
  });

  $('.site-header__mobile-submenu-toggle').on('click', (event) => {
    const toggleElement = $(event.target),
          targetId = toggleElement.attr('data-target'),
          targetElement = $(targetId);

    targetElement.toggleClass('show');
  });


  $('.site-header__mobile-sub-submenu-toggle').on('click', (event) => {
    const toggleElement = $(event.target),
          targetId = toggleElement.attr('data-target'),
          targetElement = $(targetId);

    targetElement.toggleClass('show');
  });

    if ($('#gateway-modal').hasClass('show')) $('.modal-backdrop').css('z-index', 980);

 $('#gateway-modal, #site-header-search').on('shown.bs.modal', function () {
    $('.modal-backdrop').css('z-index', 980);
});

/* sticky nav shadow */
$('.site-header__nav-item').hover(function() {
  $('.site-header.sticky').css('z-index', 1060);
}, function() {
  $('.site-header.sticky').css('z-index', 1020);
});

/*****
 *  THESE ARE MOVED UNDER THE FUND SCRIPTS SINCE THEY ARE ONLY USED IN THE FUND EXPLORER.
 * 
 * 

  const $table = $('.table');
  $table.floatThead({
    top: 84,
    zIndex: 1,
    responsiveContainer: function($table) {
      return $table.closest('.tab-content');
    },
    getSizingRow: function($table) {
      return $table.find('tbody tr:not(.grouping):visible:first>*');
    },
    useAbsolutePositioning: true,
  });

  // Table header leg for IE
  $('.fund-explorer-table').each(function() {
    if (isInterentExplorerDevice) {
      $('body').on('mousewheel', function() {
        event.preventDefault();
        const wheelDelta = event.wheelDelta;
        const currentScrollPosition = window.pageYOffset;
        window.scrollTo(0, currentScrollPosition - wheelDelta);
      });
    }
    document.getElementsByTagName('body')[0].addEventListener('wheel', function(event) {
      event.stopPropagation();
    }, true);
  });

  // Prices table - view and hide data
  $('.fund-explorer-table__data-link').on('click', function() {
    const cell = $(this),
          parent = cell.closest('.fund-explorer-table__expandable-row');
    if (cell.hasClass('collapsed')) {
      parent.addClass('selected-row');
    }
    else {
      parent.removeClass('selected-row');
    }
  });


    **/

  waypoints.init();
  waypoints.sectionHeaderInit();
  waypoints.tableHeaderInit();
});

/* scroll to top */
$(window).scroll(function() {
  if ($(window).scrollTop() >= $('main').offset().top + $('main').outerHeight() - window.innerHeight) {
    $('.scroll-to-top').css({ 'animation': 'fadeIn 0s', 'visibility': 'visible' });
    $('.scroll-to-top').addClass('table-sticky').removeClass('sticky-fixed');
  }
  else if ($(this).scrollTop() < 100) {
    $('.scroll-to-top').css({ 'animation': 'fadeIn 0s', 'visibility': 'hidden' });
  }
  else {
    $('.scroll-to-top').css({ 'animation': 'fadeOut 0s', 'visibility': 'visible' });
    $('.scroll-to-top').addClass('sticky-fixed').removeClass('table-sticky');
  }
});

$('.scroll-to-top').on('click', function() {
  $('.scroll-to-top').css({ 'animation': 'fadeIn 0s', 'visibility': 'hidden' });
  window.scrollTo({ top: 0, behavior: 'smooth' });
});

/* sticky nav shadow */
$('.site-header__nav-item').hover(function() {
  $('.site-header.sticky').css('z-index', 1060);
}, function() {
  $('.site-header.sticky').css('z-index', 1020);
});

// Print page
$('.share-widget .print').on('click', function () {
    $('.share-widget').removeClass('show');
    $('.share-widget__menu').removeClass('show');
    window.print();
});

// Trap focus inside modal and menus
const focusableEl = 'button, a, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';

$('.focusable-menu').each(function () {
    const selectFocusElement = $(this);

    const firstFocusableEl = selectFocusElement[0].querySelectorAll(focusableEl)[0];
    const focusableCn = selectFocusElement[0].querySelectorAll(focusableEl);
    const lastFocusableEl = focusableCn[focusableCn.length - 1];

    document.addEventListener('keydown', function (e) {
        const isTabPressed = e.key === 'Tab' || e.keyCode === 9;
        if (!isTabPressed) {
            return;
        }
        if (e.shiftKey) {
            if (document.activeElement === firstFocusableEl) {
                lastFocusableEl.focus();
                e.preventDefault();
            }
        }
        else {
            if (document.activeElement === lastFocusableEl) {
                firstFocusableEl.focus();
                e.preventDefault();
            }
        }
    });
    if (firstFocusableEl != undefined && typeof firstFocusableEl.focus == "function") {
        firstFocusableEl.focus();
    }
    
});

// Facebook Url
$('.share-widget').each(function () {
    const url = window.location.href;
    const facebook = $('.share-widget #facebook');

    facebook.attr({
        'href': `https://www.facebook.com/sharer.php?u=${url}`,
        'target': '_blank',
    });
});

// Alert bar
$('.alert-bar__ctalink').on('click', function () {
    $('.alert-bar__content').hide();
});

// Linkedin Url
//$(function () {
//    const webIntentEl = $('.share-widget__menu-inner #linkedin');

//    if (webIntentEl) {
//        webIntentEl.each(function () {
//            const intent = $(this);
//            const url = new URL(intent.attr('href'));
//            url.searchParams.set('url', window?.location?.href);
//            intent.attr('href', url.href);
//        });
//    }
//});

// Follow and Share Widget menu position
function followAndShareWidgetPosition() {
    const widgetDropdownEL = $('.widget.dropdown');

    if (widgetDropdownEL) {
        widgetDropdownEL.each(function (i) {
            if (widgetDropdownEL[i].classList.contains("ignore-placement-fix")) {
                return;
            }

            const widgetEl = $(this);

            const widgetOffsetLeft = widgetEl.offset().left;
            const widgetOffsetRight = $('body').innerWidth() - widgetEl.width() - widgetEl.offset().left;
            const widgetMenuWidth = 300;
            const offset = 5;
            const widgetCaretPosition = widgetMenuWidth / 2 - offset;

            const widgetMenuEl = widgetEl.find('.widget-menu');

            if (widgetOffsetLeft > widgetCaretPosition && widgetOffsetRight > widgetCaretPosition) {
                widgetMenuEl.removeClass('widget-menu-left widget-menu-center widget-menu-right');
                widgetMenuEl.addClass('widget-menu-center');
            }
            else if (
                widgetOffsetLeft > widgetCaretPosition &&
                widgetOffsetRight < widgetCaretPosition
            ) {
                widgetMenuEl.removeClass('widget-menu-left widget-menu-center widget-menu-right');
                widgetMenuEl.addClass('widget-menu-left');
            }
            else if (
                widgetOffsetLeft < widgetCaretPosition &&
                widgetOffsetRight > widgetCaretPosition
            ) {
                widgetMenuEl.removeClass('widget-menu-left widget-menu-center widget-menu-right');
                widgetMenuEl.addClass('widget-menu-right');
            }
        });
    }
}

followAndShareWidgetPosition();
$(window).on('resize', debounce(followAndShareWidgetPosition, 250));

function AttachmentBlock() {
    // Attachment Block
    const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            const shimmer = entry.target.querySelector('.attachment-block__content-bg-shimmer');

            if (entry.isIntersecting && shimmer) {
                shimmer.classList.add('gleam-animation');
                return;
            }
        });
    });

    document.querySelectorAll('.attachment-block').forEach((i) => {
        if (i) {
            observer.observe(i);
        }
    });

}

if (typeof IntersectionObserver != "function") {
    /* Microsoft Internet Explorer detected in. || intersection observer not present */
    //First get the IO Polyfill
    $.getScript("https://polyfill.io/v3/polyfill.min.js?features=IntersectionObserver").done(() => {
        //then run yall.
        AttachmentBlock();
    });
} else {
    AttachmentBlock();
}

// Utility menu and Register form - country and state logic
$(function () {
    $('.form-country-container').each(function () {
        const countryField = $(this);
        countryField.find('.dropdown-menu .dropdown-item').on('click', function () {
            const dropdownMenuItemLabel = $(this).text();

            // Update selected option
            countryField.find('#country-dropdown .dropdown__label').text(dropdownMenuItemLabel);
            countryField
                .find('.dropdown__button')
                .find('option')
                .each(function () {
                    const option = $(this);
                    if (option.text() === dropdownMenuItemLabel) {
                        option.attr('selected', 'selected');
                    }
                    else {
                        option.removeAttr('selected');
                    }
                });

            // Disable state field if the country is not 'United States'
            const stateDropdown = countryField.next().find('#state-dropdown');
            if (dropdownMenuItemLabel !== 'United States') {
                stateDropdown.attr({
                    disabled: true,
                    'aria-disabled': true,
                });
            }
            else {
                stateDropdown.attr({
                    'disabled': false,
                    'aria-disabled': false,
                });
            }
        });
    });
});


$('#site-header-search').on('shown.bs.modal', function () {
    $('.modal-backdrop').appendTo('.root');
});

$('#gateway-modal').on('shown.bs.modal', function () {
    $('.modal-backdrop').css('z-index', 980);
});
// alertbar button hover
$('.alert-bar__ctalink').hover(function () {
    $('.alert-bar__left-label').toggleClass('text-decoration-none');
});

// header height
$('.alert-bar__ctalink').on('click', function () {
    $('.sticky-wrapper').css('height', '95');
});

/* tabel header sticky
$(window).scroll(function() {
  $('.fund-explorer-table').each(function() {
    if ($(window).scrollTop() >= $(this).offset().top + $(this).height() ||
      $(window).scrollTop() < $(this).offset().top) {
      $(this).find('.fund-explorer-table__filter').removeClass('sticky');
    }
    else {
      $(this).find('.fund-explorer-table__filter').addClass('sticky');
    }
  });
}); 

/* popover 
$(function() {
  $('.fund-explorer-table-tooltip-item').each(function(i, e) {
    const $ele = $(e),
          $popoverLink = $ele.next('.fund-explorer-table-tooltip-content'),
          popoverID = 'popoverid' + String(Math.random()).substr(2);
    $(this).attr('id', popoverID);
    $popoverLink.attr('id', ('po' + popoverID));
    $ele.popover({
      html: true,
      trigger: 'hover',
      content: function() {
        const id = $(this).attr('id');
        return $('#po' + id).html();
      },
      popperConfig: {
        placement: 'right-start',
      },
    });
  });
});

// On Thead Popover
$('.sort-thead').hover(function() {
  $('.floatThead-container').addClass('popover-height');
}, function() {
  $('.floatThead-container').removeClass('popover-height');
});

//daterangepicker
$(function() {
  const startDate = moment('03/15/2020'),
        endDate = moment('03/15/2021');
  $('.datepicker span').html(startDate.format('MM/DD/YYYY') + ' - ' + endDate.format('MM/DD/YYYY'));
  $('.datepicker-btn').daterangepicker({ startDate: '', endDate: '' });

  function cb(ele) {
    $('.nav-item a').click(function() {
      const tabValue = $(this).text(),
            dateBtn = $(this).closest('ul').closest('div').closest('.pt-4').find('.datepicker-btn'),
            dateShow = $(this).closest('ul').closest('div').closest('.pt-4').find('.datepicker-date');
      dateShow.addClass('active-date active');
      if (tabValue === 'Last 1 Year') {
        dateBtn.data('daterangepicker').setStartDate('03/15/2020');
        dateBtn.data('daterangepicker').setEndDate('03/15/2021');
        dateShow.text('03/15/2020 - 03/15/2021');
      }
      else if (tabValue === 'Last 5 Year') {
        dateBtn.data('daterangepicker').setStartDate('03/15/2016');
        dateBtn.data('daterangepicker').setEndDate('03/15/2021');
        dateShow.text('03/15/2016 - 03/15/2021');
      }
      else if (tabValue === '2020 - Q4') {
        dateBtn.data('daterangepicker').setStartDate('10/01/2020');
        dateBtn.data('daterangepicker').setEndDate('12/31/2020');
        dateShow.text('10/01/2020 - 12/31/2020');
      }
      else if (tabValue === '2020 - Q3') {
        dateBtn.data('daterangepicker').setStartDate('07/01/2020');
        dateBtn.data('daterangepicker').setEndDate('09/30/2020');
        dateShow.text('07/01/2020 - 09/30/2020');
      }
      else if (tabValue === '2020 - Q2') {
        dateBtn.data('daterangepicker').setStartDate('04/01/2020');
        dateBtn.data('daterangepicker').setEndDate('06/30/2020');
        dateShow.text('04/01/2020 - 06/30/2020');
      }
      else if (tabValue === '2020 - Q1') {
        dateBtn.data('daterangepicker').setStartDate('01/01/2020');
        dateBtn.data('daterangepicker').setEndDate('03/31/2020');
        dateShow.text('01/01/2020 - 03/31/2020');
      }
      else {
        return;
      }
    });

    $('.datepicker-btn').on('apply.daterangepicker', function() {
      const tabSelect = $(this).closest('div').parent('div').prev('.fund-explorer-table__graph');
      $(this).prev('.datepicker-date').removeClass('active');
      if ($('.datepicker .datepicker-date').hasClass('active-date')) {
        tabSelect.find('.nav-link.active').removeClass('active');
      }
    });

    return function(startDate, endDate) {
      const datepickerSpan = $(ele).prev('span');
      datepickerSpan.html(startDate.format('MM/DD/YYYY') + ' - ' + endDate.format('MM/DD/YYYY'));
      datepickerSpan.addClass('active-date');
      datepickerSpan.removeClass('active');
    };
  }

  $('[id^=date-]').each(function() {
    const idRange = $(this).attr('id');
    $(this).daterangepicker({
      parentEl: '#daterange-' + idRange,
      startDate: '03/15/2020',
      endDate: '03/15/2021',
      autoUpdateInput: false,
      linkedCalendars: false,
      showCustomRangeLabel: false,
      cancelClass: 'daterangepicker-cancelbtn',
      opens: 'center',
      drops: 'up',
      locale: {
        format: 'MM/DD/YYYY',
        applyLabel: 'Go',
        cancelLabel: '',
      },
    }, cb(this));
  });
  cb(this)(startDate, endDate);
});

$(document).ready(function() {
  $('.daterangepicker .ranges').after('<h5 class="daterangepicker-title">Select Date Range</h5>');
});

//Date selector popup - unbold
$('.fund-explorer-table__graph .nav-item a').click(function() {
  if ($(this).find('active')) {
    $('.datepicker .active-date').addClass('active');
  }
});

*/
