/*
 Code for the twitter share link
 */

const { current } = require('../../../../js/scrollify');

$(document).ready(function () {
    if ($("body.page-class-insights").length == 0 && $("body.voices-page").lenght == 0) return
    init = function () {
        loadTwitterBlock();
        loadLinkedInUrl();
        openShareModal();
    }
    openShareModal = function () {
        var urlParams = new URLSearchParams(window.location.search);
        var submitted = urlParams.has('openForm');
        console.log(submitted)
        if (submitted) {
            $('#friend-form').modal('show');
        }
    }

    $(".share-with-a-friend-form__close.close-icon").click(function() {
        window.commonFunctions.trimUrl();
    })
    
    loadTwitterBlock = function () {
        console.log("called twitter");
        const twitterBlocks = $('#twitter, .callout-text .callout-text__icon-link');
        if (twitterBlocks) {
            twitterBlocks.each(function () {
                var createdUrl = window.location;
                var text = $(this).data("text");
                var author = $(this).data("author") ? "-" + $(this).data("author") : "";
                var composedUrl = "https://twitter.com/intent/tweet?text=" + encodeURIComponent(text) + " " + encodeURIComponent(author) + "&url=" + createdUrl;
                $(this).attr("href", composedUrl);
            })
        }
    }

    loadLinkedInUrl = function () {
        const linkedInBlocks = $('.share-widget__menu-inner #linkedin');
        if (linkedInBlocks) {
            linkedInBlocks.each(function () {
                var composedUrl = "https://www.linkedin.com/cws/share?url=" + window.location
                $(this).attr("href", composedUrl);
            });
        }
    }

    init();
    
});

window.commonFunctions = {
    hideElementOnOffset: (offset, element) => {
        var currentScrollTopVal = $(window).scrollTop();
        if (currentScrollTopVal > offset) {
            element.hide();
        } else {
            element.show();
        }
    },
    splitUrl: (currentLocation) => {
        var splitURL = currentLocation.split("#")[0];
        if (splitURL.includes("?")) {
            var newURL = splitURL + "&openForm=true";
        } else {
            var newURL = splitURL + "?openForm=true";
        }
        window.location.href = newURL;
    },
    trimUrl: () => {
        var tempLocation = window.location.href;
        if (tempLocation.includes("&openForm=true")) {
            var baseURL = tempLocation.split("&openForm")[0];
        } else if (tempLocation.includes("?openForm=true")) {
            var baseURL = tempLocation.split("?openForm")[0];
        }
        window.history.pushState("", "", baseURL);
    }

}