$(document).ready(function() {
    var excludeParameters = ["country", "country2", "audience", "paudience", "disclaimer", "abp", "abp2", "iif", "cleardlcookie", "defaultaudience", "pagena", "clearprevurl", "prevurl", "vehicle"];

    
    var selectors = {
        hiddenSelectedCountry: 'input#hdnSelectedCountry',
        hiddenSelectedInvestorType: 'input#hdnSelectedAudience',
        hiddenCountryUrl: 'input#hdnCountryUrl',
        dataUrlSelector: 'input#hdnDataUrl',
        countries: 'div.countries',
        countrySelectClick: 'div.countries button',
        countryLabel: 'span.country_label',
        investorTypeButton: 'span.investortype_label',
        investorTypeSelectClick: 'div.investortypes button',
        investorTypeSelectClickRetail: 'div.investortypes button[data-id=retail]',
        investorTypeDropdown: 'div.investortypes',
        hiddenLanguage: 'input#hdnLanguage',
        disclaimerHeader: '#disclaimer-header',
        hiddenCurrentLanguage: '#hdnCurrentLanguage',
        disclaimerSection: '#disclaimer-section',
        disclaimerBlock: '#disclaimer_block',
        disclaimerBlockHeader: '#disclaimer_block .header',
        disclaimerBlockText: '#disclaimer_block div.mb-4.text',
        disclaimerBlockAgree: '#disclaimer_block .agree',
        disclaimerBlockDecline: '#disclaimer_block .decline',
        gatewayButton: '#gateway_button',
        currentCountryName: '#hdnCurrentCountryName',
        ahpLangDropdown: 'div#ahp-lang-dropdown',
        ahpLangDropdownButton: 'div#ahp-lang-dropdown #dropdownMenuButton',
        ahpLangDropdownClick: 'div#ahp-lang-dropdown ul li .dropdown-item', //anchor tag removed and therefore the class is used
        selectCountryText: '#select-country-text',
        selectedCountryName: '#hdnAhpSelectedCountryName',
        ahpLangDropdownList: '.ahp-dropdown div#ahp-lang-dropdown ul',
        siteLanguageSelector: 'div.gateway div#site-lang-dropdown ul .dropdown-item', //anchor tag removed and therefore the class is used
        gatewayModal: '.modal.gateway__modal.show'
    };

    const sitecountries = ["United States", "Australia", "Brazil", "Canada", "Chile", "China", "Peru", "Rest of Asia", "Rest of World"];

    var actionMethods = {
        getInvestorAndLanguages: 'getinvestorandlanguages',
        getTranslations: 'gettranslations',
        getDisclaimer: 'getdisclaimer'
    };

    acceptClickEvent = function(url, target) {

        var decodedUrl = decodeURIComponent(url);
        if (target === '') {
            target = '_self';
        }
        window.location.target = target;
        var parentUrl = window.location.toString();
        if (parentUrl.match('#')) {
            decodedUrl = decodedUrl + '#' + parentUrl.split('#')[1];
        }

        window.location.href = decodeURIComponent(decodedUrl);
        return false;
    };

    declineClickEvent = function(url, target) {
        var decodedUrl = decodeURIComponent(url);
        if (target === '') {
            target = '_self';
        }
        window.location.target = target;
        var parentUrl = window.location.toString();
        if (parentUrl.match('#')) {
            decodedUrl = decodedUrl + '#' + parentUrl.split('#')[1];
        }
        window.location.href = decodeURIComponent(decodedUrl);
        return false;
    };

    endsWith = function(str, suffix) {
        return str.indexOf(suffix, str.length - suffix.length) !== -1;
    };

    removeUrlParameter = function(url, parameter) {
        var urlParts = url.split('?');
        if (urlParts.length >= 2) {
            var urlBase = urlParts.shift();
            var queryString = urlParts.join('?');
            var prefix = encodeURIComponent(parameter) + '=';
            var parts = queryString.split(/[&;]/g);
            for (var i = parts.length; i-- > 0;) {
                if (parts[i].lastIndexOf(prefix, 0) !== -1) {
                    parts.splice(i, 1);
                }
            }
            url = urlBase + '?' + parts.join('&');
        }
        return url;
    };

    investorTypeSelection = function(thisaction, event, action, investorType, selectedCountry, returnUrl, currentUrl, defaultDeepLinkSession, countryName) {

        $(selectors.hiddenSelectedInvestorType).val(thisaction.data('id'));
        var disclaimerJson = getData(action, $(selectors.hiddenSelectedInvestorType).val(), selectedCountry, returnUrl, currentUrl, $(selectors.hiddenLanguage).val(), defaultDeepLinkSession, countryName);
        disclaimerJson.done(function(data) {

            if (data.Text !== null && data.Text !== '') {
                $(selectors.disclaimerBlockHeader).html(data.Header);
            } else {
                $(selectors.disclaimerBlockHeader).html('');
            }

            $(selectors.disclaimerBlockText).html(data.Text);
            $(selectors.disclaimerBlockAgree).attr('data-agree', data.AcceptUrl);
            $(selectors.disclaimerBlockAgree).text(data.AgreeText);
            $(selectors.disclaimerBlockDecline).text(data.DeclineText);
            $(selectors.disclaimerBlockDecline).attr('data-decline', data.DeclineUrl);
            $(selectors.disclaimerBlockDecline).show();
            if (sitecountries.includes(countryName)) {
                $(selectors.disclaimerBlock).removeClass("disclaimer-eu");
            } else {
                $(selectors.disclaimerBlock).addClass("disclaimer-eu");
            }

            $(selectors.disclaimerBlock).show();
            $(selectors.disclaimerBlockAgree).click(function(e) {
                acceptClickEvent($(this).data('agree'), '_self');
            });
            $(selectors.disclaimerBlockDecline).click(function(e) {
                declineClickEvent($(this).data('decline'), '_self');
            });

            //enable the agree button if disabled already.
            $(selectors.disclaimerBlockAgree).removeAttr("disabled");
        });
        return false;
    };

    removeUrlParameters = function(url) {
        var returnUrl = '';
        $.each(excludeParameters, function(key, value) {
            if (returnUrl !== '') {
                returnUrl = removeUrlParameter(returnUrl, value);
            } else {
                returnUrl = removeUrlParameter(url, value);
            }
        });
        if (endsWith(returnUrl, "?") || endsWith(returnUrl, "&")) {
            returnUrl = returnUrl.slice(0, -1);
        }
        return returnUrl;
    };

    getData = function(action, param1, param2, param3, param4, param5, param6, param7) {
        return $.ajax({
            type: "GET",
            url: $(selectors.dataUrlSelector).val(),
            data: {
                "op": action,
                "param1": param1,
                "param2": param2,
                "param3": param3,
                "param4": param4,
                "param5": param5,
                "param6": param6,
                "param7": param7
            },
            cache: false,
            async: false
        });
    };

    investorTypeButtonClick = function(thisEvent, countryName) {
        var currentUrl = encodeURIComponent(removeUrlParameters(window.location.pathname + window.location.search));
        var defaultDeepLinkSession = '';
        if ($('body').hasClass('disclaimer-deeplink')) {
            defaultDeepLinkSession = 'true';
        }
        $(selectors.investorTypeButton).html(thisEvent.html());
        var e = e || false;
        investorTypeSelection(thisEvent, e, actionMethods.getDisclaimer, $(selectors.hiddenSelectedInvestorType).val(), $(selectors.hiddenSelectedCountry).val(), '', currentUrl, defaultDeepLinkSession, countryName);
    };

    $(selectors.countrySelectClick).click(function(e) {
        var countryName = $(this).data('name');
        $(selectors.selectedCountryName).val(countryName);
        var blockId = $(this).data('blockid');
        var flagName = "dropdown mb-4 global-nav-flag country-" + countryName.toLowerCase().replace(/\s+/g, "-");
        $('#countryFlag').removeClass().addClass(flagName);
        $(selectors.hiddenSelectedCountry).val($(this).data('id'));
        $(selectors.countryLabel).html(e.target.innerText.trim());
        var hiddenLanguage = $(selectors.hiddenLanguage).val();
        var currentUrl = encodeURIComponent(removeUrlParameters(window.location.pathname + window.location.search));

        var jsonData = getData(actionMethods.getInvestorAndLanguages, blockId, hiddenLanguage, $(this).data('id'), currentUrl);
        $(selectors.disclaimerBlock).hide();
        jsonData.done(function(json) {
            if (json.InvestorType !== null) {
                var investorHtml = '';
                $.each(json.InvestorType, function (key, value) {
                    investorHtml += '<button data-id="' + key + '" class="dropdown-item dropdown__item text-util-sm content-item-t" role="menuitem" type="button" value="' + value + '">' + value + '</button>';
                });
                $(selectors.investorTypeDropdown).html(investorHtml);
            }
            if (json.Languages !== null) {
                var languageHtml = '';

                var length = 0;
                for (var key in json.Languages) {
                    length++;
                }
                var hiddenLanguageInLanguages = false;
                if (length > 0) {
                    $.each(json.Languages, function(key, value) {
                        languageHtml += '<li><a class="dropdown-item language-content-item-t"  style="font-size:.624375rem;padding:8px" data-id="' + key + '">' + value + '</a></li>';
                        if (hiddenLanguage.toLowerCase() == key.toLowerCase()) {
                            hiddenLanguageInLanguages = true;
                        }
                    });
                    $(selectors.ahpLangDropdownList).html(languageHtml);
                } else {}
                if (length > 1) {
                    $("#gateway-modal #language_dropdown_arrow").show();
                    $("#gateway-modal span.disclaimer-ahp-controls div#ahp-lang-dropdown ul.dropdown-menu").removeClass("hideEmptyLanguageDropdown");
                } else {
                    $("#gateway-modal #language_dropdown_arrow").hide();
                    $("#gateway-modal span.disclaimer-ahp-controls div#ahp-lang-dropdown ul.dropdown-menu").addClass("hideEmptyLanguageDropdown");
                }
                if (hiddenLanguageInLanguages == false) {
                    $("#gateway-modal #language_dropdown_arrow").show();
                    $("#gateway-modal span.disclaimer-ahp-controls div#ahp-lang-dropdown ul.dropdown-menu").removeClass("hideEmptyLanguageDropdown");
                }
                
            }
            $(selectors.ahpLangDropdownClick)
                .click(function(e) {
                    changeAhpLanguage($(this));
                });

            $(selectors.countries).removeClass('show');

                $(selectors.investorTypeButton).html(json.SelectInvestorText);

                $(selectors.investorTypeSelectClick).click(function (e) {

                    investorTypeButtonClick($(this), countryName);
                });
            
            const containsCountry = sitecountries.includes(countryName);
            if (!containsCountry) {
                //UCITS Only
                //since the country has changed, we are going to reset the investor type to empty, then in the below function trigger the retail investor.
                $(selectors.hiddenSelectedInvestorType).val('');
                autoselectInvidualInvestor();
            }


        });
        return false;
    });

    $(selectors.investorTypeSelectClick).click(function(e) {
        investorTypeButtonClick($(this), $(selectors.currentCountryName).val());
    });

    $(selectors.gatewayButton).click(function(e) {
        $(selectors.disclaimerBlock).hide();
    });

    $(selectors.disclaimerBlockAgree).click(function(e) {
        acceptClickEvent($(this).data('agree'), '_self');
    });

    $(selectors.disclaimerBlockDecline).click(function(e) {
        declineClickEvent($(this).data('decline'), '_self');
    });


    $('.ahp-dropdown #site-lang-dropdown').hide();
    $('.ahp-dropdown #ahp-lang-dropdown').show();

    autoselectInvidualInvestor = function () {
        //See if no investor type has been selected
        if ($(selectors.hiddenSelectedInvestorType).val() == '') {

            //Temporarily disable the accept button
            $(selectors.disclaimerBlockAgree).attr("disabled", "disabled");

            //Trigger a click on the Individual Investor Button, which will run an ajax for the disclaimer.
            $(selectors.investorTypeSelectClickRetail).click();

            //focus on the agree button
            $(selectors.disclaimerBlockAgree).focus();

            $(selectors.gatewayModal).animate({
                scrollTop: $('.scroll-to-investor').offset().top - $(selectors.gatewayModal).offset().top + $(selectors.gatewayModal).scrollTop()
            });
        }
    }

    changeAhpLanguage = function(lang) {
        var target = lang;
        var oldLanguageIso = $(selectors.hiddenLanguage).val();
        $(selectors.hiddenLanguage).val(target.data('id'));


        var audienceType = $(selectors.hiddenSelectedInvestorType).val();

        var countryIso = $(selectors.hiddenSelectedCountry).val();
        var countryName = '';
        var countryUrl = $(selectors.hiddenCountryUrl).val();
        if ($(selectors.disclaimerHeader).length > 1) {
            audienceType = $(selectors.disclaimerHeader).data('audience');
            countryIso = $(selectors.disclaimerHeader).data('countryiso');
            countryName = $(selectors.disclaimerHeader).data('countryname');
            countryUrl = $(selectors.disclaimerHeader).data('accepturl');
        } else {
            audienceType = $(selectors.hiddenSelectedInvestorType).val();
            countryIso = $(selectors.hiddenSelectedCountry).val();
            countryName = $(selectors.selectedCountryName).val();

            countryUrl = $(selectors.hiddenCountryUrl).val();
        }
        var defaultDeepLinkSession = '';
        if ($('body').hasClass('disclaimer-deeplink')) {
            defaultDeepLinkSession = 'true';
        }
        var selectedCountryName = $(selectors.countryLabel).html().trim();
        countryName = $(selectors.selectedCountryName).val();
        if (countryName == "") {
            countryName = $(selectors.currentCountryName).val();
        }
        var translations = getData(actionMethods.getTranslations, target.data('id'), audienceType, countryIso, countryUrl, countryName, oldLanguageIso, defaultDeepLinkSession);
        translations.done(function(translation) {
            $(".ahp-dropdown #dropdownMenuButton #ahpSelectedLanguage").text(target[0].innerHTML);
            $.each(translation.List, function(index, value) {
                $('.content-item-t').each(function() {
                    var regex = new RegExp(value.Text, 'g');
                    var translatedHtml = $(this).html().replace(regex, value.TranslatedText);
                    $(this).html(translatedHtml);
                });
            });
            $.each(translation.LanguageList, function(index, value) {
                $('.language-content-item-t').each(function() {
                    var regex = new RegExp(value.Text, 'g');
                    var translatedHtml = $(this).html().replace(regex, value.TranslatedText);
                    $(this).html(translatedHtml);
                });
            });
            for (var i = 0; i < translation.List.length; i++) {
                if (translation.List[i].Text.trim().toLowerCase() == selectedCountryName.trim().toLowerCase()) {
                    $(selectors.countryLabel).html(translation.List[i].TranslatedText.trim());
                    break;
                }
            }

            if ($(selectors.disclaimerBlockText).html().trim().length > 1) {
                var contentHtml = '';
                if (translation.Text !== null && translation.Text !== '') {

                    $(selectors.disclaimerBlockHeader).html(translation.Header);
                    $(selectors.disclaimerBlockText).html(translation.Text);
                    $(selectors.disclaimerBlockAgree).attr('data-agree', translation.AcceptUrl);
                    $(selectors.disclaimerBlockAgree).text(translation.AgreeText);
                    $(selectors.disclaimerBlockDecline).text(translation.DeclineText);
                    $(selectors.disclaimerBlockDecline).attr('data-decline', translation.DeclineUrl);
                    $(selectors.disclaimerBlockDecline).show();

                } else {
                    $(selectors.disclaimerBlock).hide();

                    contentHtml = '<div class="text-util-md mb-2 header"></div>' +
                        '<p class="mb-4 text"></p>' +
                        '<div class="mt-3">' +
                        '<button class="btn btn-primary gateway__action agree" data-ve-gtm="ahp-disclaimer-agree" data-dismiss="modal" data-agree="' + translation.AcceptUrl + '">' + translation.AgreeText + '</button>' +
                        '<button class="btn btn-white decline  gateway__action decline" data-ve-gtm="ahp-disclaimer-decline" onclick=declineClickEvent("' + translation.DeclineUrl + '")>' + translation.DeclineText + '</button>' +
                        '</div>';
                }

            }

            $(selectors.disclaimerBlockAgree).click(function(e) {
                acceptClickEvent($(this).data('agree'), '_self');
            });
            $(selectors.disclaimerBlockDecline).click(function(e) {
                declineClickEvent($(this).data('decline'), '_self');
            });
        });
    };

    $(selectors.ahpLangDropdownClick)
        .click(function(e) {
            changeAhpLanguage($(this));
        });

    $(selectors.ahpLangDropdownButton)
        .click(function(e) {
            var targetAnchor = $(this);
            var showAllElements = $(this).parent().find('ul').find('li').find('div');
            showAllElements.parent().show();

            $(this).find('ul li').each(function(i) {
                $(this).show();
            });
            var elemToHide = $(this).parent().find('ul').find('li').find('div:contains("' + $.trim(targetAnchor.text()).trim() + '")');
            elemToHide.parent().hide();
        });

    $(selectors.siteLanguageSelector)
        .click(function(e) {
            e.preventDefault();
            var selectedDropdownElement = this;
            $.post("/common/languages/select/", { language: $(this).data("language"), sourcePath: location.pathname + location.search }, function(response) {
                if (response !== '') {
                    //check to see if there is a url-override on the language dropdown.
                    if (typeof $(selectedDropdownElement).data("url-override") == "string") {
                        location.href = $(selectedDropdownElement).data("url-override"); return;
                    }
                    location.href = removeUrlParameter(response, 'lang');
                }
            });
        });

    autoselectInvidualInvestorOnDeepLink = function () {
        //get the currently chosen country
        const currentCountry = $("#select-country-text").text().trim();
        
        //See if the country is a ucits country, if not return.
        if(sitecountries.includes(currentCountry)) return;

        //auto select the individual investor for EU
        autoselectInvidualInvestor();
    }

    //Check if gateway has been opened and add default UCITS stuff (i.e. deep link).
    if($("#gateway-modal").hasClass("show")) autoselectInvidualInvestorOnDeepLink();

    //in case the gateway is closed by default, check again and autoselected if needed.
    $("#gateway-modal").on("show.bs.modal", autoselectInvidualInvestorOnDeepLink);


});