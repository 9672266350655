/**
 * CORP-SCRIPTS
 * ============
 *
 * This is the main Javascript bundle for the CORP website.
 */

//1. index.js is from Vshift
require("./sources/index.js");
require('./override/override.js');

// TODO:[JC] - WEBUS-167 - I'm not sure if this is correct place to add this js. 
require('./override/jquery-ui-autocomplete.min.js');

// Vaneck
require('../../../js/global-translation/global-translation-section-provider');
require('../../../js/global-translation/global-translation-api-service');
require('../../../js/global-translation/global-translation-service');