function homepageScripts() {

/*** Homepage ***/
/* Show the hero banners once the page has loaded */
$('main > div.compound-hero').removeClass('d-none');



/* support lazy loading on home-page and other slider */
$(".slider-for, .carousel-block__main--desktop").on("lazyLoadError", function (e, slick, image, imageSource) {
    parentSlide = $(image).parent(".compound-hero__bg, .insight-card__image");
    src = image.data("src"); //get source
    parentSlide.css("background-image", 'url("' + src + '")').addClass("loaded"); //replace with background instead
    image.remove();
});

    //For mp4 banner instead of static banner'
    const banner = document.getElementById('video-banner');
    const bannerHome = document.getElementById('video-banner-home');
    if (banner) {
        function setVideoBanner(data) {
            let vData;
            vData = banner.getAttribute(data);
            vData !== undefined && banner.setAttribute("src", vData);
            bannerHome.load();
        }
        ($(window).width() <= 480) ? setVideoBanner("data-mobilesource") : setVideoBanner("data-desktopsource");
    }
}

window.addEventListener("DOMContentLoaded", homepageScripts)