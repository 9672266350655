/* This is the exandable menu component which is **REWRITTEN** based on original React code, since we cannot reuse TS code here. */

import { renderShareMenu } from "./share-menu";
import { debounce } from "lodash";

async function init() {
    if (window.ExpandableMenu) return false;
    window.ExpandableMenu = true;


    //get the parent element to render
    const parentElement = document.getElementsByTagName("ve-expandablemenu")[0];

    //Do nothing if the utility menu itself is not there.
    if (typeof parentElement === "undefined") return;

    squareBlocks = getSquareBlocks();

    //update the insights and funds link
    await updateLinksForInsightsFunds(parentElement);

    const expandableMenu = renderExpandableMenu({});
    parentElement.innerHTML = expandableMenu;
    bindEventListeners();
}

let expanded = false;

let squareBlocks = [];

//The base blocks, each of these blocks represents one of the square blocks.
const getSquareBlocks = () => [
    {
        name: veDictionary.exploreMore ?? "Explore More",
        attributes: {
            id: "expand-close-button"
        },
        gtmAttribute: "sidenav-openclose",
        expandable: true,
        expandedLabel: "Close",
        icon: `/globalassets/home/us/common/icons/explore-more.svg`,
        expandedIcon: `/globalassets/home/us/common/icons/close.svg`,
    },
    {
        name: veDictionary.subscribeLinkText ?? "Subscribe",
        gtmAttribute: "sidenav-subscribe",
        attributes: {
            id: "subscribe-widget-button",
        },
        icon: `/globalassets/home/us/common/icons/subscribe.svg`,
    },
    {
        name: veDictionary.share ?? "Share",
        gtmAttribute: "sidenav-share",
        attributes: {
            id: "share-widget-button",
        },
        icon: `/globalassets/home/us/common/icons/share.svg`,
        childItem: renderShareMenu,
    },
    {
        name: veDictionary.insights ?? "Insights",
        gtmAttribute: "sidenav-insights",
        icon: `/globalassets/home/us/common/icons/insights.svg`,
        type: "insights",
    }/*,
  {
    name:  veDictionary.funds ?? "Funds",
    gtmAttribute: "sidenav-funds",
    icon: `/globalassets/home/us/common/icons/funds.svg`,
    type: "funds",
  },*/
];

async function updateLinksForInsightsFunds(expandableMenuTag) {
    /**
     * Overrides are not implemented but useful later if someone wants to override the link for a specific page.
     */

    //get the override insights link if available
    let insightsLink = document.getElementsByTagName("ve-insightslink")?.item(0)?.innerHTML ?? "";
    //get the override funds link if available
    let fundsLink = document.getElementsByTagName("ve-fundslink")?.item(0)?.innerHTML ?? "";
    let insightsLinkTarget = "_self";
    let fundsLinkTarget = "_self";

    try {
        //Get PageID
        const pageID = expandableMenuTag.dataset.pageid;
        const categoryID = expandableMenuTag.dataset.categoryid;
        const ticker = document.getElementsByTagName("ve-fundticker")[0]?.innerHTML ?? null;

        const links = await getFundPageLinks({ pageID: pageID, ticker: ticker, categoryID: categoryID });

        //override the current links with the new links received.
        ["categoryLink", "fundListingLink"].forEach(linkType => {
            if (typeof (links[linkType]) === "undefined" || links[linkType] === null || !links[linkType].Text) return null;
            if (linkType === "categoryLink") {
                insightsLink = links[linkType].Link;
                insightsLinkTarget = links[linkType].Target;
            }
            if (linkType === "fundListingLink") {
                fundsLink = links[linkType].Link;
                fundsLinkTarget = links[linkType].Target;
            }
        })
    }
    catch (err) {
        console.log("Failed getting the fund page links.");
        console.error(err);
    }

    //Update the insights link if available.
    const insightsBlockIndex = squareBlocks.findIndex((x) => x.type == "insights");
    if (insightsLink == "") {
        squareBlocks.splice(insightsBlockIndex, 1); //remove if no link
    } else {
        squareBlocks[insightsBlockIndex].link = insightsLink;
        squareBlocks[insightsBlockIndex].target = insightsLinkTarget;
    }

    //Update the fund link if available. -- Fund links are not used in CORP
    //let fundsBlockIndex = squareBlocks.findIndex((x) => x.type == "funds");
    //if (fundsLink == "") {
    //    squareBlocks.splice(fundsBlockIndex, 1); //remove if no link
    //} else if ( squareBlocks[fundsBlockIndex] != undefined ) {
    //    squareBlocks[fundsBlockIndex].link = fundsLink;
    //    squareBlocks[fundsBlockIndex].target = fundsLinkTarget;
    //}
}

function renderExpandableMenu() {
    const visibleClass = ""; //was a react state before, set initial now = not visible;
    return `<div class='expandable-nav ${visibleClass}'>
  <div class="expandable-nav__blocks">
      ${renderBlocks(squareBlocks, expanded)}
  </div>
</div>`;
}

/**
 * Takes in an array of blocks representing the intial state and renders it.
 * @param {any} blocks
 */
function renderBlocks(blocks, expanded = true) {
    //iterate through blocks and build markup array.
    return blocks.reduce((prev, block) => {
        //block.expandable = if the block can be used to expand/shrink the menu. i.e. if it is the main block used to toggle between the expanded and contracted states.
        const hideClass = block.expandable || expanded ? "show" : ""; //main block is always shown
        const canBeHiddenClass = !block.expandable ? "can-be-hidden" : ""; //denotes that the block can be hidden in the contracted state

        const text = expanded && block.expandable ? block.expandedLabel : block.name;
        const toggleText = !expanded && block.expandable ? block.expandedLabel : block.name;

        const childItem = block.childItem ?? false;
        const activeClass = expanded && block.expandable ? "opened" : "";

        const icon = (expanded && block.expandable ? block.expandedIcon : block.icon) ?? "";
        const toggleIcon = (!expanded && block.expandable ? block.expandedIcon : block.icon) ?? "";

        const childMarkup = typeof childItem === "function" ? childItem() : "";

        const idAttr = block.attributes?.id ? `id="${block.attributes.id}"` : "";
        const gtmAttr = block.gtmAttribute ? `data-ve-gtm="${block.gtmAttribute}"` : "";


        const blockInnerMarkup = `<button
        type="button"
        class="expandable-nav__block d-flex flex-column ${activeClass} ${hideClass} ${canBeHiddenClass}" ${idAttr} ${gtmAttr}>
        <div class="expandable-nav__block-icon" ${gtmAttr}><img src='${icon}' ${gtmAttr} data-expandable-src='${
      toggleIcon ?? ""
            }' alt='${text}' width="25px" height="25px" /></div>
        <div class="expandable-nav__text text-util-sm" data-expandable-label='${toggleText ?? ""}' ${gtmAttr}>${text}</div>
      </button>${childMarkup}`;

        if (block.link) {
            return prev + `<a href='${block.link}' ${gtmAttr} target='${block.target ?? '_blank'}'>${blockInnerMarkup}</a>`;
        }
        return prev + blockInnerMarkup;
    }, "");
}

function bindEventListeners() {
    //hide and show the menu when toggle button is clicked.
    const toggleBlocks = document.querySelectorAll(".expandable-nav__block:not(.can-be-hidden)");
    toggleBlocks.forEach((x) => x.addEventListener("click", toggleExpanded));
    function toggleExpanded(e) {

        //get all items that can be hidden
        const hidables = Array.from(document.getElementsByClassName("can-be-hidden"));

        //toggle the class
        hidables.forEach((x) => x.classList.toggle("show"));

        //flip any expandable items's icon and text
        //get unhidable items
        toggleBlocks.forEach((x) => {
            //flip the text, if available.
            const textElement = x.querySelectorAll(".expandable-nav__text")?.item(0);
            if (textElement) {
                const label = textElement.dataset.expandableLabel;
                if (label && label != "") {
                    textElement.dataset.expandableLabel = textElement.textContent;
                    textElement.textContent = label;
                }
            }

            //flip the icon, if available.
            const iconElement = x.querySelectorAll(".expandable-nav__block-icon img")?.item(0);
            if (iconElement) {
                const label = iconElement.dataset.expandableSrc;
                if (label && label != "") {
                    iconElement.dataset.expandableSrc = iconElement.attributes.getNamedItem("src").value;
                    iconElement.setAttribute("src", label);
                }
            }
        });

        //set Global State;
        expanded = !expanded;
        const expandableNavs = document.querySelectorAll(".expandable-nav");
        expandableNavs.forEach(x => x.classList.toggle("not-expanded"));
    }

    //Events for the share widget
    const expandCloseButton = document.querySelectorAll(".expandable-nav #expand-close-button").item(0);
    const shareWidget = document.querySelectorAll(".expandable-nav #share-widget-button").item(0);
    const subscribeWidget = document.querySelectorAll(".expandable-nav #subscribe-widget-button").item(0);
    //To trigger the share popover.
    window.$(shareWidget).popover({
        content: window.$(".expandable-nav #share-widget-popover-content"),
        container: window.$(".expandable-nav .expandable-nav__blocks"),
        title: "",
        html: true,
        placement: "left",
        trigger: "click",
    });

    //ref for the parent container for the share popover
    const expandableNavRef = document.querySelectorAll(".expandable-nav .expandable-nav__blocks").item(0).parentElement;
    //show a backdrop when the popover is triggered.
    window.$(shareWidget).on("show.bs.popover", () => {
        let backdrop = document.createElement("div");
        backdrop.id = "backdrop";
        backdrop.classList.add("modal-backdrop", "in", "show");
        backdrop.style.zIndex = "10000";

        //For very old layouts, z-index issues need to be fixed.
        if (document.querySelectorAll(".mainWrapper > .container-m").length > 0) {
            document.querySelectorAll(".mainWrapper > .container-m")[0].appendChild(backdrop);
        }
        else {
            document.body.appendChild(backdrop);
        }


        //adjust the z-index of the menu so it is infront of the backdrop.
        if (expandableNavRef) {
            expandableNavRef.style.zIndex = "10100";
            shareWidget?.classList.add("active");
        }
        //hide the popover when the user clicks the backdrop.
        backdrop.addEventListener("click", () => window.$(shareWidget).popover("hide"));
        expandCloseButton.addEventListener("click", () => window.$(shareWidget).popover("hide"))
    });

    //remove the backdrop when the popover is hidden.
    $(shareWidget).on("hide.bs.popover", () => {
        let backdrop = document.getElementById("backdrop");
        backdrop?.remove();

        //adjust the Expandable Nav so the z-index is back.
        if (expandableNavRef) {
            expandableNavRef.style.zIndex = "0";
            shareWidget?.classList.remove("active");
        }
    });

    //print listener
    window.$(shareWidget).on("shown.bs.popover", () => {
        $(".share-widget__action.print").click((e) => window.$(shareWidget).popover("hide") && window.print());
    });

    //subscribe click event
    window.$(subscribeWidget).click((e) => {
        window.$(shareWidget).popover("hide");
        return window.$("#default-state-utility-menu").modal("show");
    });

    //Scroll listener to only show it below the fold.
    const scrollListener = debounce(() => {
        const heightOffset = window.innerHeight / 4;
    const ScrolledTop  = window.pageYOffset || document.documentElement.scrollTop
        if (ScrolledTop > heightOffset) {
            expandableNavRef.classList.add("visible");
            return window.removeEventListener("scroll", scrollListener);
        }
    }, 100);

    window.addEventListener("scroll", scrollListener);

    scrollListener(); //trigger it once anyway, incase the page is already scrolled down.

    // get height
    const floatingNavbar = document.getElementsByClassName('expandable-nav__blocks')[0];
    const floatingNavBarHeight = floatingNavbar.clientHeight;

    // fix height
    floatingNavbar.style.minHeight = floatingNavBarHeight + "px";
}

async function getFundPageLinks({pageID, ticker, categoryID}) {
    const response = await fetchBlockData({
        url: `/main/portfoliopage/getussidelinks/?ticker=${ticker ?? ""}&contentId=${pageID}&categoryId=${categoryID ?? ""}`,
    });
    return await response;
}

async function fetchBlockData({ url }) {
    try {
        const request = await fetch(url);
        const response = await request.json();
        console.log(response);
        return response;
    } catch (err) {
        console.log(`Error while fetching expandable nav data! URL: ${url}`);
        throw new Error(err);
    }
}

window.addEventListener("DOMContentLoaded", init);