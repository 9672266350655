/**
 *  customDropdown
 *  ==============
 *
 *  Dynamically replaces Epi dropdowns (std html) with bootstrap custom dropdowns.
 *
 *  TODO: Old code written in pre-ES6, update when possible.
 *
 */

let customDropdown = {
    init: function (form, dropClass) {
        $(form).find(dropClass).each(function () {
            customDropdown.addDropdown($(this)); // Add dropdowns
            customDropdown.dropdownEventHandler($(this)); //Dropdown event handler
        });
    },
    dropdownEventHandler: function (dropWrap) {
        dropWrap.find(".dropdown-item").click(function () {
            customDropdown.changeSelector(dropWrap.find("select"), $(this).attr("value"));
            console.log(dropWrap.find(".dropdown__button span"), "text");
            dropWrap.find(".dropdown__button span").text($(this).text());
            //dropWrap.find(".dropdown__button").attr("data-value", $(this).attr("value"));
        });
    },
    changeSelector: function (dropdown, selectedVal) {
        dropdown.find("option").removeAttr("selected");
        dropdown.find("option").each(function () {
            if ($(this).attr('value') == selectedVal) {
                $(this).attr("selected", "selected");
                dropdown.val(selectedVal).change();
            }                
        });
    },
    addDropdown: function (dropWrap) {
        var _html = '';
        var selectElm = dropWrap.find("select");        

        _html += '<button aria-haspopup="true" aria-expanded="false" data-value="' + selectElm.find("option:selected").val() +'" class="dropdown__button dropdown__button-default text-util-sm" data-toggle="dropdown" id="example-dropdown" type="button">';
        _html += '<span class="dropdown__label" >' + selectElm.find(":selected").text() +'</span >';
        _html += '<svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="chevron-down" class="svg-inline--fa fa-chevron-down fa-w-16 fa-2x dropdown__icon dropdown__icon-default" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">';
        _html += '<path fill="currentColor" d="M2.26 4.88a.75.75 0 00-.54.21.75.75 0 000 1.07l6.75 6.75a.75.75 0 001.06 0l6.75-6.75a.75.75 0 000-1.07.75.75 0 00-1.06 0L9 11.31 2.78 5.1a.75.75 0 00-.52-.21z"></path>';
        _html += '</svg></button>';

        _html += '<div class="dropdown-menu dropdown__menu text-util-sm" role="menu">';

        selectElm.find("option").each(function () {
            _html += '<button class="dropdown-item dropdown__item text-util-sm" role="menuitem" type="button" value="' + $(this).val() +'">'+$(this).text()+'</button>';
        });

        _html += "</div>";

        selectElm.hide();
        dropWrap.append(_html);
    }
}

window.addEventListener('DOMContentLoaded', () => {
        customDropdown.init(".custom-select-form", ".dropdown");
    }
);