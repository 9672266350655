/**
 *  Interstitial
 *  ==============
 *
 *  The VanEck Interstitial for CORP.
 *
 *
 */

const interstitial = {
    init: function () {
        $('.open-interstitial').on("click", function () {
            var content = $(".interstitial-content-wrapper").html();
            $("#popupDialog .modal-body").append(content);
        });

        $('#popupDialog').on('click', function (e) {
            var popup = $('.modal-content');
            if (!popup.is(e.target) && popup.has(e.target).length === 0) {
                $("body").hasClass("modal-open") ? $("#popupDialog .modal-body .interstitial-content").remove() : "";
            }
        });

        $('#popupDialog .modal-header button').on("click", function () {
            $("#popupDialog .modal-body .interstitial-content").remove();
        });
    }
}

window.addEventListener('DOMContentLoaded', interstitial.init);