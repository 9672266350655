require('waypoints/lib/noframework.waypoints.js');
require('waypoints/lib/jquery.waypoints.js');
require('waypoints/lib/shortcuts/sticky.js');

export function init() {
  const element = document.getElementById('sticky-waypoint');
  if (element) {
    new Waypoint({ // eslint-disable-line no-undef
      element,
      handler: function(direction) {
        if (direction === 'down') this.element.classList.add('sticky');
        if (direction === 'up') this.element.classList.remove('sticky');
      },
    });
  }
}

/* eslint-disable no-undef */
export function sectionHeaderInit() {
  const element = document.getElementById('sticky-site-header');
  if (element) {
    new Waypoint.Sticky({
      element: $('#sticky-site-header')[0],
      stuckClass: 'sticky',
    });
  }
}

/* eslint-disable no-undef */
export function tableHeaderInit() {
  const element = document.getElementById('sticky-site-header-table');
  if (element) {
    new Waypoint.Sticky({
      element: $('#sticky-site-header-table')[0],
      stuckClass: 'sticky',
    });
  }
}
