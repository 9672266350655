//import $ from 'jquery';

declare global {
    interface Window {
        flagUserAsRegistered: (modalId: string) => void; 
    }
}

import { getAssociatedGatedContentBlocks } from "./gated-content-block";
import { initialiseSimpleStickyPremiumContent } from "./simple-sticky-premium-content";
import { checkIfGatedContentPage, initialiseModal } from "./toggle-modal";

function stickyPremiumForm() {
    //return function if .sticky-premium-content-form doesn't exist
    if (!$('.sticky-premium-content-form').length) {
        return;
    }

    $(".sticky-premium-content-form #veSpinnerContainer").hide()

    $('.sticky-premium-content-form:not(.premium-content-modal)').each(function () {
        initialiseSimpleStickyPremiumContent(this);
    });

    //handle premium content modals
    $('.sticky-premium-content-form.premium-content-modal').each(function () {
        $(this).find('button.close-icon').click(function () {
            $(this).parents(".premium-content-modal").hide();
        });
    });

    const stickyPremiumContentFormModals = document.querySelectorAll('.sticky-premium-content-form.premium-content-modal');
    window.flagUserAsRegistered = flagUserAsRegistered;
    stickyPremiumContentFormModals.forEach((modal) => {
        initialiseModal(modal);
    });
}

window.addEventListener("DOMContentLoaded", stickyPremiumForm);

function flagUserAsRegistered(modalId: string) {
    //unhide any associated gated content
    const associatedBlocks = getAssociatedGatedContentBlocks(modalId);
    if (associatedBlocks) {
        associatedBlocks.forEach(x => x.classList.add("unlocked"));
    }

    //if it is a gated content block, remove the _blank from the link

    //check if it a gated content page
    const isGatedContent = checkIfGatedContentPage();

    const modal = document.getElementById(modalId);
    if (modal && isGatedContent) {
        const links = modal.querySelectorAll("a");
        links.forEach(link => {
            link.removeAttribute("target");
        });
    }
    localStorage.setItem(modalId, 'registered');
}
