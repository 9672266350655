/**
 *  Miscellaneous Components
 *  ==============
 *
 *  SMall code not pertinant to any of the other modules.
 *
 * IF you code is more than 20 lines long, add it as a separate module/file, do not uglify this file.
 *
 */

/*** INTERACTIVE IMAGES
 * Apparel Page and other interactive image cards - Transition the background image
 *  Background -> secondary image on hover. **
 */
$('.interactive-image.card').hover(function () {
    const template = $(this).data('content')
    const image = $(template).attr('src')
    $(this).css("background-image", "url(" + image + ")");
    $(this).addClass("interactive-hover");
}, function () {
    $(this).css("background", "none");
    $(this).removeClass("interactive-hover");
});