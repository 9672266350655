$(document).ready(function () {
    const form = $('.utility-menu__capability-insights form.custom-select-form');
    if (form.length === 0) return
    const dropDownElement = form.find("select.Country")[0].id;
    if (typeof(dropDownElement) == "undefined") return
    const dropDownElementId = document.getElementById(dropDownElement);
    const ctext = $("#select-country-text").text().trim();
    $(`#${dropDownElement}`).siblings('button').find('span').html(ctext);
    for (var i = 0; i < dropDownElementId.options.length; i++) {
        let p = dropDownElementId.options[i];
        (p.innerHTML == ctext) ? p.setAttribute('selected', 'selected') : p.removeAttribute('selected');
    }
});