const globalTranslationApiService = class {
    baseUrl = '/common/GlobalTranslationApi';

    fetchSection(sectionName, language, country) {
        const promise = fetch(`${this.baseUrl}/FetchTranslationSection/?sectionName=${sectionName}&language=${language}&country=${country}`)
            .then(data => data.json());
        return promise;
    }

    fetchSections(sectionNames, language, country) {
        const promise = fetch(`${this.baseUrl}/FetchTranslationSections/?sectionNames=${sectionNames}&language=${language}&country=${country}`)
            .then(data => data.json());
        return promise;
    }

    fetchSectionHash(sectionName, language, country) {
        const promise = fetch(`${this.baseUrl}/FetchTranslationSectionHash/?sectionName=${sectionName}&language=${language}&country=${country}`)
            .then(data => data.text());
        return promise;
    }

    fetchSectionsHashes(sectionNames, language, country) {
        const promise = fetch(`${this.baseUrl}/FetchTranslationSectionsHashes/?sectionNames=${sectionNames}&language=${language}&country=${country}`)
            .then(data => data.json());
        return promise;
    }
}

export default new globalTranslationApiService();