const globalTranslationAlphabeticalSectionProvider = class {
    constructor() {
        const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        const digits = "0123456789";

        const letterSectionMapping = [...letters].reduce((sectionMapping, letter) => (sectionMapping[letter] = letter, sectionMapping), {});
        const digitSectionMapping = [...digits].reduce((sectionMapping, letter) => (sectionMapping[letter] = '0-9', sectionMapping), {});
        this.sectionMapping = Object.assign({}, letterSectionMapping, digitSectionMapping);
        this.otherCaseSectionName = "other";
    }

    getTranslationSection(key) {
        const firstLetter = [...key].find(x => x != '_').toUpperCase();
        const section = this.sectionMapping[firstLetter];
        return section !== undefined
            ? section
            : this.otherCaseSectionName;
    }
}

export default new globalTranslationAlphabeticalSectionProvider();