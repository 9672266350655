/**
 *  Search
 *  ==============
 *
 *  To drive search functionality.
 *
 *
 */

const searchListners = function () {

    var searchInputAutoCompMinLen = 2;

    /* Navigates to the search page once you press enter on the search bar. */
    $(".search__input").keyup(function (e) {
        console.log("dsadsadsa");
        if (e.which == 13) {
            window.location.href = window["sharedData"].SearchFormatString.replace("{0}", encodeURIComponent(e.currentTarget.value));
        }
        $(e.currentTarget).val().length < searchInputAutoCompMinLen ? $(".auto-comp-list").empty() : false;
        $(e.currentTarget).removeClass('search__input-top-curved');
    })



    $('.search-results__input').keydown(function (e) {
        
        if (e.keyCode == 13) {
            $('.search-results__submit').click();
            return false;
        }
    });

    $('.search-results__submit').click(function () {
        const searchString = $('.search-results__input').val();
        if (searchString) {
            window.location.href = "/row/search/?searchtext=" + encodeURIComponent(searchString);
        }
        return false;
    });

    $('#main-search-box').keydown(function (e) {
        if (e.keyCode == 13) {
            var searchString = $('#main-search-box').val();
            if (searchString) {
                window.location.href = "/row/search/?searchtext=" + encodeURIComponent(searchString);
            }
            return false;
        }
    });


    // event for "shown.bs.modal" was not working as bootstrap js is not loaded in corp chile. 
    // Adding bootstrap js was causing other issues.
    // TODO: Verify if this is still needed.
    $(".nav-link.px-0").mouseup(function () {
        setTimeout(function () { $('input.search__input').focus(); }, 500);
    });

    $(".search__input").attr("list", "search-input-suggests");

    $(".search__container").append("<div class='search-auto-comp'><ul class='auto-comp-list'></ul></div>");
}

window.addEventListener('DOMContentLoaded', searchListners);